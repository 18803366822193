import { useReducer, useState, useEffect } from 'react';
import {
  Button,
  Grid,
  List,
  Paper,
  Drawer,
  Box,
  Typography,
  Pagination,
  IconButton,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  TextField,
  Stack,
} from '@mui/material';
import { EnumObjectType } from 'Interfaces/IGlobalTypeConfig';
import { IProductViewModel } from 'Interfaces/IProductConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';
import { useGet } from 'http/useInnovit';
import { rq } from 'http/apiRoutes';
import { t } from 'i18next';
import { SingleEmployeeDrawerCompanyProductView } from 'components/ui/SingleEmployeeDrawerCompanyProductView';
import { SingleEmployeeDrawerStorageProductView } from 'components/ui/SingleEmployeeDrawerStorageProductView';
import { SingleEmployeeDrawerProductView } from 'components/ui/SingleEmployeeDrawerProductView';
import { Height } from '@mui/icons-material';
import useWindowsDimension from 'functions/useWindowsDimension';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

// Types
interface State {
  companyProducts: ICompanyProductViewModel[];
  productCatalog: IProductViewModel[];
  storageProducts: IStorageProductViewModel[];
}

interface Action {
  type: string;
  payload?: any;
}

interface Props {
  employeeId: string;
  handleSelection: (selectedProducts: {
    companyProducts: ICompanyProductViewModel[];
    catalogProducts: IProductViewModel[];
    storageProducts: IStorageProductViewModel[];
    isOnboarding: boolean;
  }) => void;
}

// Reducer and Initial State
const initialState: State = {
  companyProducts: [],
  productCatalog: [],
  storageProducts: [],
};

const productReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_COMPANY_PRODUCTS':
      return { ...state, companyProducts: action.payload };
    case 'SET_PRODUCT_CATALOG':
      return { ...state, productCatalog: action.payload };
    case 'SET_STORAGE_PRODUCTS':
      return { ...state, storageProducts: action.payload };
    default:
      return state;
  }
};

export const EmployeeProductDrawer = ({
  employeeId,
  handleSelection,
}: Props) => {
  const [state, dispatch] = useReducer(productReducer, initialState);
  const { height } = useWindowsDimension();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedCompanyProducts, setSelectedCompanyProducts] = useState<any[]>(
    []
  );
  const [selectedCatalogProducts, setSelectedCatalogProducts] = useState<any[]>(
    []
  );
  const [selectedStorageProducts, setSelectedStorageProducts] = useState<any[]>(
    []
  );
  const [currentProducts, setCurrentProducts] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [objectType, setObjectType] = useState<EnumObjectType>(
    EnumObjectType.Product
  );

  // API Call to fetch products
  let isEnabled = employeeId !== 'new' ? true : false;
  const { data: getProducts } = useGet(
    rq.employeeDrawerProducts(employeeId, isEnabled),
    isEnabled
  );

  useEffect(() => {
    if (getProducts) {
      // Dispatch data from API to state
      dispatch({
        type: 'SET_COMPANY_PRODUCTS',
        payload: getProducts.companyProducts,
      });
      dispatch({
        type: 'SET_PRODUCT_CATALOG',
        payload: getProducts.productCatalog,
      });
      dispatch({
        type: 'SET_STORAGE_PRODUCTS',
        payload: getProducts.storageProducts,
      });

      // Sett standardprodukter som de første tilgjengelige produktene
      if (getProducts.companyProducts.length > 0) {
        setCurrentProducts(getProducts.companyProducts);
        setObjectType(EnumObjectType.unassignedCompanyProduct);
      } else if (getProducts.productCatalog.length > 0) {
        setCurrentProducts(getProducts.productCatalog);
        setObjectType(EnumObjectType.Product);
      } else if (getProducts.storageProducts.length > 0) {
        setCurrentProducts(getProducts.storageProducts);
        setObjectType(EnumObjectType.StorageProduct);
      }
    }
  }, [getProducts]);

  // Function to handle product selection
  const toggleProductSelection = (product: any, type: EnumObjectType) => {
    if (type === EnumObjectType.Product) {
      setSelectedCatalogProducts((prevSelected) => {
        const isAlreadySelected = prevSelected.some((p) => p.id === product.id);
        if (isAlreadySelected) {
          return prevSelected.filter((p) => p.id !== product.id);
        }
        return [...prevSelected, product];
      });
    } else if (type === EnumObjectType.StorageProduct) {
      setSelectedStorageProducts((prevSelected) => {
        const isAlreadySelected = prevSelected.some((p) => p.id === product.id);
        if (isAlreadySelected) {
          return prevSelected.filter((p) => p.id !== product.id);
        }
        return [...prevSelected, product];
      });
    } else if (type === EnumObjectType.unassignedCompanyProduct) {
      setSelectedCompanyProducts((prevSelected) => {
        const isAlreadySelected = prevSelected.some((p) => p.id === product.id);
        if (isAlreadySelected) {
          return prevSelected.filter((p) => p.id !== product.id);
        }
        return [...prevSelected, product];
      });
    }
  };

  // Function to handle removing products from the selected lists
  const removeProductSelection = (product: any, type: EnumObjectType) => {
    if (type === EnumObjectType.Product) {
      setSelectedCatalogProducts((prevSelected) =>
        prevSelected.filter((p) => p.id !== product.id)
      );
    } else if (type === EnumObjectType.StorageProduct) {
      setSelectedStorageProducts((prevSelected) =>
        prevSelected.filter((p) => p.id !== product.id)
      );
    } else if (type === EnumObjectType.unassignedCompanyProduct) {
      setSelectedCompanyProducts((prevSelected) =>
        prevSelected.filter((p) => p.id !== product.id)
      );
    }

    // Oppdater valgte produkter separat
    /*  handleSelection({
      companyProducts: selectedCompanyProducts,
      catalogProducts: selectedCatalogProducts,
      storageProducts: selectedStorageProducts,
      isOnboarding: isOnboarding,
    }); */
  };

  // Lagre valgte produkter separat
  const saveSelection = (e: any) => {
    e.preventDefault();

    handleSelection({
      companyProducts: selectedCompanyProducts,
      catalogProducts: selectedCatalogProducts,
      storageProducts: selectedStorageProducts,
      isOnboarding: isOnboarding,
    });
    setSelectedCompanyProducts([]);
    setSelectedCatalogProducts([]);
    setSelectedStorageProducts([]);

    setDrawerOpen(false);
  };

  // Function to handle pagination
  const handlePageChange = (event: any, value: number) => {
    setPage(value);
  };

  // Paginate the products
  const paginateProducts = (products: any[]) => {
    const startIndex = (page - 1) * itemsPerPage;
    return products.slice(startIndex, startIndex + itemsPerPage);
  };

  // Render buttons for product categories
  const renderButtonsForLists = () => {
    return (
      <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
        {state.companyProducts.length > 0 && (
          <Button
            variant='contained'
            sx={{ margin: 2 }}
            onClick={() => {
              setCurrentProducts(state.companyProducts);
              setObjectType(EnumObjectType.unassignedCompanyProduct);
              console.log(
                'product: X Setting objectType to unassignedCompanyProduct'
              );
            }}
          >
            {t('employeeDrawer.availableProducts')} (
            {state.companyProducts.length})
          </Button>
        )}
        {state.productCatalog.length > 0 && (
          <Button
            variant='contained'
            sx={{ margin: 2 }}
            onClick={() => {
              setCurrentProducts(state.productCatalog);
              setObjectType(EnumObjectType.Product);
            }}
          >
            {t('employeeDrawer.orderProduct')} ({state.productCatalog.length})
          </Button>
        )}
        {state.storageProducts.length > 0 && (
          <Button
            variant='contained'
            sx={{ margin: 2 }}
            onClick={() => {
              setCurrentProducts(state.storageProducts);
              setObjectType(EnumObjectType.StorageProduct);
            }}
          >
            {t('employeeDrawer.storageProduct')} ({state.storageProducts.length}
            )
          </Button>
        )}
      </Grid>
    );
  };

  const clearSelection = () => {
    setSelectedCompanyProducts([]);
    setSelectedCatalogProducts([]);
    setSelectedStorageProducts([]);
    console.log('Selection cleared.');
  };

  // Render the product list with selection toggle
  const renderProductList = (products: any[]) => {
    console.log('product: X Current objectType:', objectType); // Logg objectType
    console.log('product: X Filtered products:', products); // Logg produktene som vises
    return (
      <>
        <List
          sx={{
            width: '100%',
            p: 2,
            height: height - 200,
            backgroundColor: 'primary.back',
            overflow: 'auto',
          }}
        >
          {paginateProducts(products).map((product, index) => (
            <Paper
              key={index}
              sx={{
                margin: 1,
                backgroundColor:
                  selectedCatalogProducts.some((p) => p.id === product.id) ||
                  selectedStorageProducts.some((p) => p.id === product.id) ||
                  selectedCompanyProducts.some((p) => p.id === product.id)
                    ? 'primary.main'
                    : 'primary.main',
                ':hover': {
                  cursor: 'pointer',
                  filter: 'brightness(120%)',
                  transition: 'all 0.2s ease-in-out',
                },
              }}
              onClick={() => toggleProductSelection(product, objectType)}
            >
              {/* Conditionally render based on the product type */}
              {objectType === EnumObjectType.Product && (
                <SingleEmployeeDrawerProductView
                  product={product as IProductViewModel}
                  isOnboarding={isOnboarding}
                />
              )}
              {objectType === EnumObjectType.StorageProduct && (
                <SingleEmployeeDrawerStorageProductView
                  storageProduct={product as IStorageProductViewModel}
                />
              )}
              {objectType === EnumObjectType.unassignedCompanyProduct && (
                <SingleEmployeeDrawerCompanyProductView
                  companyProduct={product as ICompanyProductViewModel}
                />
              )}
            </Paper>
          ))}
        </List>

        {/* Pagination */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            count={Math.ceil(products.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
          <Typography sx={{ mr: 2 }}>Items per page:</Typography>
          <Select
            size='small'
            variant='outlined'
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
            sx={{ width: 85, mt: -1 }}
          >
            {[5, 10, 15, 20, 25, 50, 100].map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </>
    );
  };

  // Render the selected products inside the drawer on the right side
  const renderSelectedProducts = () => {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Typography variant='h6' sx={{ mb: 3 }}>
          Selected Products
        </Typography>

        {/* Selected Company Products */}
        <Typography variant='subtitle1'>
          {t('employeeDrawer.reuseProducts').toUpperCase()}
        </Typography>
        <List
          sx={{
            width: '100%',
            mb: 2,
            p: 1,
            backgroundColor: 'primary.back',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          {selectedCompanyProducts.map((product, index) => (
            <Paper
              key={index}
              sx={{ m: 1, cursor: 'pointer' }}
              onClick={() =>
                removeProductSelection(
                  product,
                  EnumObjectType.unassignedCompanyProduct
                )
              }
            >
              <SingleEmployeeDrawerCompanyProductView
                companyProduct={product as ICompanyProductViewModel}
              />
            </Paper>
          ))}
        </List>

        {/* Selected Product Catalog with Onboarding Switch */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
          <Typography variant='subtitle1'>
            {t(
              isOnboarding
                ? 'employeeDrawer.OnboardingProduct'
                : 'employeeDrawer.productToBeOrdered'
            ).toUpperCase()}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color={isOnboarding ? 'secondary' : 'primary'}
                checked={isOnboarding}
                onChange={() => setIsOnboarding(!isOnboarding)}
              />
            }
            label='Onboarding'
            sx={{ marginLeft: 'auto' }}
          />
        </Box>
        <List
          sx={{
            width: '100%',
            mb: 2,
            p: 1,
            backgroundColor: 'primary.back',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          {selectedCatalogProducts.map((product, index) => (
            <Paper
              key={index}
              sx={{ m: 1, cursor: 'pointer' }}
              onClick={() =>
                removeProductSelection(product, EnumObjectType.Product)
              }
            >
              <SingleEmployeeDrawerProductView
                product={product as IProductViewModel}
                isOnboarding={isOnboarding}
              />
            </Paper>
          ))}
        </List>

        {/* Selected Storage Products */}
        <Typography variant='subtitle1'>
          {t('employeeDrawer.salesProducts').toUpperCase()}
        </Typography>
        <List
          sx={{
            width: '100%',
            mb: 2,
            p: 1,
            backgroundColor: 'primary.back',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          {selectedStorageProducts.map((product, index) => (
            <Paper
              key={index}
              sx={{ m: 1, cursor: 'pointer' }}
              onClick={() =>
                removeProductSelection(product, EnumObjectType.StorageProduct)
              }
            >
              <SingleEmployeeDrawerStorageProductView
                storageProduct={product as IStorageProductViewModel}
              />
            </Paper>
          ))}
        </List>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Button type='submit' variant='contained' onClick={clearSelection}>
            Clear
          </Button>
          <Button type='submit' variant='contained' onClick={saveSelection}>
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const renderInfo = () => {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Stack
          display='flex'
          direction='row'
          sx={{ mb: 3, justifyContent: 'space-between' }}
        >
          <Typography variant='h6' sx={{ mb: 3 }}>
            Informasjon
          </Typography>
          <Button
            type='submit'
            variant='contained'
            onClick={() => setDrawerOpen(false)}
          >
            X
          </Button>
        </Stack>

        {/* Selected Company Products */}
        <Typography variant='subtitle1' sx={{ height: 30 }}></Typography>
        <List
          sx={{
            width: '100%',
            mb: 3,
            p: 1,
            backgroundColor: 'transparent',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          <Typography variant='subtitle1'>
            {t('employeeDrawer.reuseInfo')}
          </Typography>
        </List>

        {/* Dynamic content based on isOnboarding */}
        {/* Selected Company Products */}
        <Typography variant='subtitle1' sx={{ height: 30 }}></Typography>
        <List
          sx={{
            width: '100%',
            mb: 3,
            p: 1,
            backgroundColor: 'transparent',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          <Typography variant='subtitle1'>
            {t(
              isOnboarding
                ? 'employeeDrawer.onboardingInfo'
                : 'employeeDrawer.orderInfo'
            )}
          </Typography>
        </List>

        {/* Selected Storage Products */}

        <Typography variant='subtitle1' sx={{ height: 30 }}></Typography>
        <List
          sx={{
            width: '100%',
            mb: 2,
            p: 1,
            backgroundColor: 'transparent',
            height: height / 3 - 100,
            overflow: 'auto',
          }}
        >
          <Typography variant='subtitle1'>
            {t('employeeDrawer.storageInfo')}
          </Typography>
        </List>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        ></Box>
      </Box>
    );
  };

  const filteredProducts = currentProducts.filter((product) => {
    // Hvis søkefeltet er tomt, returner alle produkter
    if (!searchQuery) {
      return true;
    }

    const searchableFields = ['name', 'description', 'productType', 'brand'];
    const targetProduct = product.product || product;

    // Utfør filtrering på targetProduct
    return searchableFields.some((field) => {
      const fieldValue = targetProduct[field];
      return (
        fieldValue &&
        fieldValue.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  });

  return (
    <>
      <Box
        sx={[
          {
            width: 32,
            height: 32,
          },
        ]}
      >
        <IconButton
          sx={{
            p: 0,
            m: 0,
            width: 18,
            height: 18,
          }}
          onClick={() => setDrawerOpen(true)}
        >
          <AddRoundedIcon sx={{ fontSize: 26 }} />
        </IconButton>
      </Box>

      <Drawer
        anchor='left'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{ width: '100%', flexShrink: 0 }}
        PaperProps={{ style: { width: '100%' } }}
      >
        <Box sx={{ display: 'flex', padding: 2 }}>
          {/* Left side: Product list */}
          <Box sx={{ width: '40%' }}>
            {/* Render category buttons */}
            {renderButtonsForLists()}

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <Typography variant='h6'>Product List</Typography>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Search products'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ width: 200 }}
              />
            </Box>

            {renderProductList(filteredProducts)}
          </Box>

          {/* Right side: Selected products */}
          <Box sx={{ width: '40%' }}>{renderSelectedProducts()}</Box>

          {/* Right side: Selected products */}
          <Box sx={{ width: '20%' }}>{renderInfo()}</Box>
        </Box>
      </Drawer>
    </>
  );
};
