import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { useTranslation } from 'react-i18next';
import { ICompanyProductViewModel } from 'Interfaces/ICompanyProductConfig';

interface SingleEmployeeDrawerCompanyProductViewProps {
  companyProduct: ICompanyProductViewModel;
}

export const SingleEmployeeDrawerCompanyProductView = ({
  companyProduct,
}: SingleEmployeeDrawerCompanyProductViewProps) => {
  const { t } = useTranslation();
  const product = companyProduct;
  
  console.log('product: X ', product);  
  
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(
          product?.product?.productType?.name ?? 'noColor'
        ),
        backgroundColor: 'primary.main',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
        height: 84,
      }}
    >
      {/* Image Column */}
      <Grid
        item
        sx={{
          backgroundColor: 'primary.light',
          width: 84,
          height: 84,
        }}
      >
        <img
          src={product?.product?.url ?? MissingPhoto.PRODUCT_URL}
          style={{
            borderRadius: 3,
            objectFit: 'scale-down',
            width: 80,
            height: 80,
          }}
          alt={product?.owner?.name ? `Owner: ${product.owner.name}` : ''}
        />
      </Grid>

      {/* Product Details Column */}
      <Grid item xs={5.5} sx={{ pl: 0.5 }}>
        <Typography
          sx={{
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 18,
          }}
        >
          {product?.product.name || ' '}
        </Typography>
        <Stack display={'flex'} direction={'row'} spacing={0}>
          <Grid
            item
            xs={2}
            sx={{
              width: 56,
              height: 56,
            }}
          >
            <img
              src={product?.owner?.url ?? MissingPhoto.EMPLOYEE_URL}
              style={{
                borderRadius: 30,
                objectFit: 'cover',
                width: 56,
                height: 56,
              }}
              alt={product?.owner?.name ? `Owner: ${product.owner.name}` : ''}
            />
          </Grid>
          <Grid item xs={10} sx={{ pr: 1, pl: 0.5 }}>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: 12,
              }}
            >
              {product?.owner?.name || ' '}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {product?.owner?.phone || ' '}
            </Typography>
            <Typography
              sx={{
                letterSpacing: 1,
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {product?.owner?.email || ' '}
            </Typography>
          </Grid>
        </Stack>
      </Grid>

      {/* Service Tag and Category Column */}
      <Grid item xs={3}>
        <Typography
          sx={{
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            letterSpacing: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
            fontSize: 14,
          }}
        >
          {product?.serviceTag ||
            `${t('company.singleCompanyProductView.noServiceTag')}`}
        </Typography>
        <Typography
          sx={{
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            letterSpacing: 1,
            textAlign: 'left',
            fontSize: 14,
          }}
        >
          {product?.product?.productCategory ||
            `${t('company.singleCompanyProductView.noCategory')}`}
        </Typography>
        <Typography
          sx={{
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            letterSpacing: 1,
            textAlign: 'left',
            fontSize: 14,
          }}
        >
          {product.product.productType.name ||
            `${t('company.singleCompanyProductView.noType')}`}
        </Typography>
      </Grid>

      {/* Brand and Pricing Column */}
      <Grid item flexGrow={1} sx={{ pr: 1 }}>
        <Typography
          noWrap
          sx={{
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            letterSpacing: 0,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'normal',
            textTransform: 'capitalize',
            fontSize: 16,
          }}
        >
          {product?.product?.brand?.brandName ||
            `${t('company.singleCompanyProductView.noBrand')}`}
        </Typography>

       {/*  <Typography
          sx={{
            mt: -0.5,
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            fontWeight: 'normal',
            color: 'primary.text',
            fontSize: 14,
          }}
        >
          {(product?.retailPrice).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
            ' * ' +
            product?.quantity}
        </Typography> */}

       {/*  <Typography
          sx={{
            minHeight: 80 / 3,
            maxHeight: 80 / 3,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            color: 'secondary.text',
            letterSpacing: 2,
            fontSize: 16,
          }}
        >
          {(product?.retailPrice * product?.quantity).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )}
        </Typography> */}
      </Grid>
    </Grid>
  );
};
