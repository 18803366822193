import { IRateViewModel } from 'data/atoms/atomConfig';
import dayjs from 'dayjs';

export const findHours = (
  type: 'night' | 'work' | 'midDay' | 'evening' | 'notBillable',
  startDate: dayjs.Dayjs,
  endDate: dayjs.Dayjs,
  rates: IRateViewModel[]
): number => {
  if (!startDate || !endDate || !rates) {
    return 0;
  }

  const interval = (() => {
    switch (type) {
      case 'night':
        return rates[0];
      case 'work':
        return rates[1];
      case 'midDay':
        return rates[2];
      case 'evening':
        return rates[3];
      case 'notBillable':
        return { startHour: 0, endHour: 24 };
      default:
        return null;
    }
  })();

  if (!interval) {
    return 0;
  }

  if (!interval) {
    return 0;
  }

  const startMinutes = startDate.hour() * 60 + startDate.minute();
  const endMinutes = endDate.hour() * 60 + endDate.minute();
  const intervalStartMinutes = interval.startHour * 60;
  const intervalEndMinutes = interval.endHour * 60;

  let totalMinutes = 0;

  for (let i = startMinutes; i < endMinutes; i++) {
    if (i >= intervalStartMinutes && i < intervalEndMinutes) {
      totalMinutes++;
    }
  }

  const totalHours = totalMinutes / 60;
  return totalHours;
};
