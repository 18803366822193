import * as React from 'react';
import { Grid, Stack, Typography, Chip } from '@mui/material';
import GetColorByName from 'functions/GetColorByName';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import { IStorageProductViewModel } from 'Interfaces/IStorageConfig';
import { SingleItemStyle } from 'data/FontStyle';

type Props = {
  storageProduct: IStorageProductViewModel;
};

export const SingleEmployeeDrawerStorageProductView = (storageProduct: Props) => {
  console.log(storageProduct);
  return (
    <Grid
      container
      flexGrow={1}
      direction={'row'}
      sx={{
        ...SingleItemStyle.container,
        borderColor: GetColorByName(
          storageProduct?.storageProduct?.product?.productType?.name ??
            'No Color To Day'
        ),
      }}
    >
      <Grid
        item
        
        sx={{
          ...SingleItemStyle.imageHolder,
         
        }}
      >
        <img
          src={
            storageProduct?.storageProduct?.product?.url
              ? storageProduct.storageProduct.product.url
              : MissingPhoto.PRODUCT_URL
          }
          style={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '6px',
            borderBottomLeftRadius: '6px',
            objectFit: 'scale-down',
          }}
          alt={'logo'}
        />
      </Grid>

      <Grid
        item
        xs={7.5}
        sx={{
          pl: 1,
          height: 88,
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{
              ...SingleItemStyle.header,
            }}
          >
            {storageProduct?.storageProduct.product?.name}
          </Typography>

          <Grid
            container
            direction={'row'}
            sx={{
              height: 56,
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {storageProduct?.storageProduct.product?.features &&
              storageProduct?.storageProduct.product?.features.map(
                (_features: any, index: number) => {
                  return (
                    <Grid item key={index} xs={6}>
                      <Grid container direction={'row'}>
                        <Grid item xs={6}>
                          <Typography
                            noWrap
                            sx={{
                              ...SingleItemStyle.featuresName,
                            }}
                          >
                            {_features.featureName}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            noWrap
                            sx={{
                              ...SingleItemStyle.featuresName,
                            }}
                          >
                            {_features.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                }
              )}
          </Grid>
        </Stack>
      </Grid>

      <Grid item xs={2} sx={{}}>
        <Stack direction={'column'}>
          <Typography
            noWrap
            sx={{
              ...SingleItemStyle.brand,
            }}
          >
            ( {storageProduct?.storageProduct.product?.brand?.brandName} )
          </Typography>
          <Typography
            sx={{
              ...SingleItemStyle.value,
            }}
          >
            {storageProduct?.storageProduct.retailPrice.toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </Typography>
        </Stack>
      </Grid>

      <Grid
        item
        xs={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Chip
          sx={{
            ...SingleItemStyle.chip,
          }}
          label={storageProduct?.storageProduct.quantity}
        />
      </Grid>
    </Grid>
  );
};
