import Typography from '@mui/material/Typography';
import { Grid, Paper, Stack, IconButton } from '@mui/material';
import GetPriorityColor from 'functions/GetPriorityColor';
import { useNavigate } from 'react-router';
import {
  MissingPhoto,
  EnumTicketStatus,
  enumTicketStatusToStringMapping,
  EnumCompanyProductStatus,
} from 'Interfaces/IGlobalTypeConfig';
import DevicesOtherTwoToneIcon from '@mui/icons-material/DevicesOtherTwoTone';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import AppShortcutRoundedIcon from '@mui/icons-material/AppShortcutRounded';
function getFormattedTimeDifference(date1: Date, date2: Date): string {
  const diffInMilliseconds: number = Math.abs(
    date2.getTime() - date1.getTime()
  ); // Absolute difference in milliseconds

  // Convert to minutes
  const diffInMinutes: number = Math.floor(diffInMilliseconds / 1000 / 60);

  // Convert to hours and minutes if the difference is at least 60 minutes
  const hours: number = Math.floor(diffInMinutes / 60);
  const minutes: number = diffInMinutes % 60;

  // Format time
  const formattedHours: string = String(hours).padStart(2, '0');
  const formattedMinutes: string = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

export const SingleTicketView = (props: any) => {
  let navigate = useNavigate();
  if (!props.ticket) {
    return null; // You can return a placeholder or null here
  }
  console.log(
    'props.ticket',
    props.ticket ? props.ticket : 'No companyProduct',
    props
  );
  const id = `${props.ticket.id}`;
  const index = props.index;
  const ticket = props?.ticket;

  const handleMouseEnter = () => {
    if (props.setIsDragDisabled) {
      props.setIsDragDisabled(true);
    }
  };

  const handleMouseLeave = () => {
    if (props.setIsDragDisabled) {
      props.setIsDragDisabled(false);
    }
  };

  // calculating date difference
  const date1 = new Date(ticket.created) as Date;
  const date2 = new Date() as Date;
  // console.log('date1', date1);
  // console.log('date2', date2);
  // console.log('ticket', ticket);

  const formattedTimeDifference: string = getFormattedTimeDifference(
    date1,
    date2
  );

  if (!ticket) {
    return (
      <Paper
        // ref={setNodeRef}
        variant='elevation3'
        sx={{
          mb: 1,
          borderLeft: 8,
          borderRight: 1,
          borderColor: GetPriorityColor(ticket.priority),
        }}
        // style={draggableStyle}
      >
        <Stack direction={'row'}>
          <Grid container direction={'row'}></Grid>
        </Stack>
      </Paper>
    );
  }

  const companyProductColor = (product: any) => {
    switch (product?.companyProductStatus) {
      case EnumCompanyProductStatus.NONE:
      case EnumCompanyProductStatus.DELIVERED:
      case EnumCompanyProductStatus.PRODUCT_RECEIVED:
        return 'success.lighter';
      case EnumCompanyProductStatus.STORAGE:
      case EnumCompanyProductStatus.SERVICE:
      case EnumCompanyProductStatus.PROCESSING:
      case EnumCompanyProductStatus.TO_DELETE:
        return 'error.lighter';
      case EnumCompanyProductStatus.AWAITING_TO_BE_ORDERED:
        return 'warning.lighter';
      case EnumCompanyProductStatus.PRODUCT_ORDERED:
        return 'error.lighter';
      case EnumCompanyProductStatus.PRODUCT_IN_TRANSIT:
        return 'error.light';

      default:
        return 'primary.lighter';
    }
  };

  console.log('TTT: ticket', ticket);

  return (
    <Paper
      // ref={setNodeRef}
      variant='elevation3'
      // onMouseEnter={handleMouseEnter}
      //  onMouseLeave={handleMouseLeave}
      onClick={(event: any) => {
        event.stopPropagation();
        if (props.onClick) {
          props.onClick();
        } else {
          navigate(`/tickets/${ticket.id}/communications`);
        }
        //console.log('event.stopPropagation', event);
      }}
      sx={{
        p: 0,
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderColor: GetPriorityColor(ticket.priority),

        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Stack direction={'row'}>
        <Typography
          sx={{
            ml: 1,
            mt: 0.7,
            flexGrow: 1,
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontWeight: 'Bold',
            fontSize: 16,
          }}
        >
          {ticket.title}
        </Typography>
        <Grid sx={{}}>
          {/* ICON ID THERE IS PRODUCT CONTECTED TO TICKET */}
          {ticket.ticketProducts &&
            ticket.ticketProducts.map((product: any, index: number) => {
              return (
                <>
                  <IconButton
                    key={index}
                    sx={{
                      p: 0,
                      mr: 0.7,
                      color: companyProductColor(product),
                    }}
                  >
                    <DevicesOtherTwoToneIcon />
                  </IconButton>
                </>
              );
            })}
        </Grid>
        <Grid sx={{ pr: 1, pt: 0.7 }}>
          <Typography
            sx={{
              m: 0,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'normal',
              fontSize: 14,
            }}
          >
            {ticket.ticketNo}
          </Typography>
        </Grid>
        <Grid sx={{ pr: 1, pt: 0.7 }}>
          {ticket.isApp ? (
            <IconButton
              key={index}
              sx={{
                p: 0,
                mr: 0.7,
                color: GetPriorityColor(0),
              }}
            >
              <AppShortcutRoundedIcon sx={{ mt: -1, fontSize: 20 }} />
            </IconButton>
          ) : (
            <IconButton
              key={index}
              sx={{
                p: 0,
                mr: 0.7,
                color: GetPriorityColor(0),
              }}
            >
              <MailOutlineRoundedIcon sx={{ mt: -1, fontSize: 20 }} />
            </IconButton>
          )}
        </Grid>
      </Stack>
      <Stack direction={'row'}>
        <Grid container direction={'row'} sx={{ pl: 1 }}>
          <Grid item sx={{ mr: 1, width: 82 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  letterSpacing: 1,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 12,
                }}
              >
                {
                  enumTicketStatusToStringMapping[
                    ticket.status as EnumTicketStatus
                  ]
                }
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  fontSize: 14,
                }}
              >
                Age {formattedTimeDifference}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ mr: 0.5, width: 128 }}>
            <Paper
              sx={{
                mx: 'auto',
                display: 'flex',
                alignSelf: 'flex-center',
                backgroundColor: 'primary.light',
              }}
            >
              <img
                src={ticket.company?.url || MissingPhoto.COMPANY_URL}
                style={{
                  padding: 1,
                  width: 128,
                  height: 64,
                  borderRadius: 3,
                  objectFit: 'scale-down',
                }}
                alt={'previewUrl'}
              />
            </Paper>
          </Grid>

          <Grid item sx={{ mr: 1, pl: 1, width: 160 }}>
            <Stack direction={'column'}>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.areaSelection}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.companyProductSelection}
              </Typography>
              <Typography
                sx={{
                  m: 0,
                  p: 0,
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 'normal',
                  fontSize: 14,
                }}
              >
                {ticket.autoAnswerSelection}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs zeroMinWidth sx={{ mr: 1, flexGrow: 1 }}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                textAlign: 'left',
                whiteSpace: 'wrap',
                fontWeight: 'normal',
                fontSize: 14,
              }}
            >
              {ticket.comment ? ticket.comment : 'No comment'}
            </Typography>
          </Grid>
          <Grid item sx={{ pr: 1 }}>
            <img
              src={ticket.contact?.url || MissingPhoto.EMPLOYEE_URL}
              style={{
                width: 64,
                height: 64,
                borderRadius: 100,
                objectFit: 'scale-down',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
              }}
              alt={'previewUrl'}
            />
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};
