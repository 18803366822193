import { useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGet, usePost, usePut } from 'http/useInnovit';
import { rq, rqPost } from 'http/apiRoutes';
import { FormBox } from 'components/form';
import {
  Button,
  Divider,
  Grid,
  Typography,
  Box,
  List,
  Stack,
  Paper,
  IconButton,
  TextField,
  Autocomplete,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import ImageUploader from 'components/ui/ImageUploader';
import useWindowsDimension from 'functions/useWindowsDimension';
import { IBrandViewModel } from 'pages/Setup/setupConfig';
import {
  EnumBindingPeriod,
  EnumCycleAlignment,
  EnumProductSecurity,
  EnumProductVariant,
  ICategoriesViewModel,
  ISelectItemViewModel,
  MissingPhoto,
  SecurityData,
  bindingPeriodData,
  cycleAlignmentData,
  enumEnumProductVariantToStringMapping,
  paymentCycleData,
} from 'Interfaces/IGlobalTypeConfig';
import {
  IProductAddViewModel,
  IProductFeatureDataUpdateViewModel,
  IProductFeatureDataViewModel,
  IProductFeatureViewModel,
  IProductFormInitialState,
  IProductUpdateViewModel,
  IProductViewModel,
} from 'Interfaces/IProductConfig';
import { SearchFilter } from 'components/ui/SearchFilter';
import { IProductTypeViewModel } from 'Interfaces/BillingConfig';
import * as yup from 'yup';
import { ProductFormStyle } from 'data/FontStyle';
import { IFileModel } from 'Interfaces/ICompaniesConfig';
import ConvertBase64StringToFileModel from 'functions/ConvertBase64StringToFileModel';
import { useTranslation } from 'react-i18next';

let _ = require('lodash');

const phoneRegex = /^\+[0-9]{2}(?=(?:\s*\d){8})([\d\s]*)$/;
const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters')
    .required('Name is required, Between 2-64 characters'),
  organizationNumber: yup
    .string()
    .required('Organization number is required')
    .min(9, 'Organization number must be at least 9 characters')
    .max(9, 'Organization number must be at most 9 characters')
    .matches(/^[0-9]{9}$/, '9 numbers required'),
  email: yup.string().email().required('Email is required'),
  // phone: yup.string().matches(/^(|[0-9]{8,16})$/, "Between 8-16 characters"),
  webPage: yup.string().notRequired(),
  // .test(
  //   'test-webPage', // Name of the test
  //   'Enter a valid webPage URL starting with www.', // Error message
  //   (value) => !value || /^www\.[\w-]+\.[a-zA-Z]{2,}$/.test(value) // Test function
  // ),
  phone: yup
    .string()
    .required('Phone number is required')
    .matches(phoneRegex, 'Enter a valid phone number'),
  mainAddress: yup.object().shape({
    street: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters')
      .required('Street is required'),
    postalCode: yup
      .string()
      .matches(/^(|[0-9]{4,4})$/, '4 numbers required')
      .required('Postal code is required'),
    city: yup
      .string()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters')
      .required('City is required'),
  }),
  deliveryAddress: yup.object().shape({
    street: yup
      .string()
      .notRequired()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters'),
    postalCode: yup
      .string()
      .notRequired()
      .matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
    city: yup
      .string()
      .notRequired()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters'),
  }),
  invoiceAddress: yup.object().shape({
    street: yup
      .string()
      .notRequired()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters'),
    postalCode: yup
      .string()
      .notRequired()
      .matches(/^(|[0-9]{4,4})$/, '4 numbers required'),
    city: yup
      .string()
      .notRequired()
      .matches(/^(|[A-åÅØøÆæ0-9 ]{2,64})$/, 'Between 2-64 characters'),
  }),
});

const ConvertToUpdateProduct = (
  productState: IProductFormInitialState,
  image: File | null,
  base64String: string | null
) => {
  let productFeatures: IProductFeatureDataUpdateViewModel[] = [];

  if (productState.productFeatureList) {
    productFeatures = productState.productFeatureList
      .map((feature: IProductFeatureViewModel) => {
        let selectedFeature: IProductFeatureDataViewModel | undefined =
          feature.productFeatureData.find(
            (f: IProductFeatureDataViewModel) =>
              f.description === feature.selectedDescription
          );

        if (selectedFeature) {
          return {
            id: selectedFeature.id,
            productFeatureId: selectedFeature.productFeatureId,
            description: selectedFeature.description,
            featureName: selectedFeature.featureName,
            priority: selectedFeature.priority,
            includeInDescriptiveName: selectedFeature.includeInDescriptiveName,
          } as IProductFeatureDataUpdateViewModel;
        } else {
          // Håndter tilfelle hvor ingen tilsvarende funksjon er funnet
          console.error(
            'No matching feature found for feature name:',
            feature.name
          );
          return undefined; // Returner undefined eller et egnet standardobjekt
        }
      })
      .filter(
        (feature) => feature !== undefined
      ) as IProductFeatureDataUpdateViewModel[]; // Fjern undefined-verdier fra resultatet
  }

  let fileModel = null;
  if (image) {
    fileModel = ConvertBase64StringToFileModel(base64String, image.name);
  }

  let productToUpdate: IProductUpdateViewModel = {
    id: productState.id,
    name: productState.name,
    comment: productState.comment,
    serialNumber: '',
    unitPrice: productState.unitPrice,
    retailPrice: productState.retailPrice,
    security: parseInt(productState.security.id) as EnumProductSecurity,
    fileModel: fileModel, // xxx
    productTypeId: productState.productType.id,
    EAN: productState.EAN ?? '',
    brandId: productState.brandId,
    quantity: productState.quantity,
    paymentCycle: parseInt(productState.paymentCycle.id) as EnumBindingPeriod,
    cycleAlignment: parseInt(
      productState.cycleAlignment.id
    ) as EnumCycleAlignment,
    bindingPeriod: parseInt(productState.bindingPeriod.id) as EnumBindingPeriod,
    isActive: productState.isActive ?? true,
    productFeatures: productFeatures,
  };

  return productToUpdate as IProductUpdateViewModel;
};
const ConvertToAddProduct = (
  productState: IProductFormInitialState,
  image: File | null,
  base64String: string | null
) => {
  let productFeatureDataIds: string[] = [];

  if (productState.productFeatureList) {
    productFeatureDataIds = productState.productFeatureList
      .map((feature: IProductFeatureViewModel) => {
        const selectedFeature = feature.productFeatureData.find(
          (f: IProductFeatureDataViewModel) =>
            f.description === feature.selectedDescription
        );

        // Returner direkte ID-en til det valgte trekket, hvis det finnes
        return selectedFeature ? selectedFeature.id : undefined;
      })
      .filter((id): id is string => id !== undefined); // Filtrer bort undefined, behold kun strengtyper
  }

  let fileModel = null;
  if (image) {
    fileModel = ConvertBase64StringToFileModel(base64String, image.name);
  }
  console.log('productState', productState);
  let productToAdd: IProductAddViewModel = {
    name: productState.name,
    comment: productState.comment,
    serialNumber: '',
    unitPrice: productState.unitPrice,
    retailPrice: productState.retailPrice,
    security: parseInt(productState.security.id) as EnumProductSecurity,
    fileModel: fileModel, // xxx
    imageFile: productState.imageFile,
    productTypeId: productState.productType.id,
    EAN: productState.EAN ?? '',
    brandId: productState.brandId,
    quantity: productState.quantity,
    paymentCycle: parseInt(productState.paymentCycle.id) as EnumBindingPeriod,
    cycleAlignment: parseInt(
      productState.cycleAlignment.id
    ) as EnumCycleAlignment,
    bindingPeriod: parseInt(productState.bindingPeriod.id) as EnumBindingPeriod,
    productFeatureDataIds: productFeatureDataIds,
    tempType: checkTempType(productState),
    legacyId: 0,
    thirdPartyId: '',
    thirdParty: 0,
  };

  return productToAdd as IProductAddViewModel;
};
const checkTempType = (productState: IProductFormInitialState) => {
  switch (productState.productCategory.category.toLowerCase()) {
    case 'software':
      return 0;
    case 'hardware':
      return 1;
    case 'network':
      return 2;
    case 'accessories':
      return 3;
    case 'other':
      return 4;
    default:
      return 0;
  }
};
const ConvertProductFeaturesData = (
  product: IProductViewModel
): IProductViewModel => {
  if (product?.productType)
    if (product.productType?.productFeatures) {
      product.productType.productFeatures.forEach((feature) => {
        let productFeatureData = product.features.find(
          (f: IProductFeatureDataViewModel) => f.featureName === feature.name
        );
        if (productFeatureData) {
          feature.selectedDescription = productFeatureData.description;
        }
      });
    }

  return product;
};
/* productTypeId: string;
  name: string;
  selectedDescription: string;
 // productType: IProductTypeViewModel;
  productFeatureData: IProductFeatureDataViewModel[];
 */
type Action =
  | { type: 'RESET_FORM' }
  | { type: 'VALIDATE' }
  | { type: 'SET_RETAIL_PRICE'; payload: number }
  | { type: 'SET_UNIT_PRICE'; payload: number }
  | {
      type: 'UPDATE_FIELD';
      field: keyof IProductFormInitialState;
      payload: any;
    }
  | { type: 'CHANGE_SECURITY'; payload: ISelectItemViewModel }
  | { type: 'CHANGE_PAYMENT_CYCLE'; payload: ISelectItemViewModel }
  | { type: 'CHANGE_CYCLE_ALIGNMENT'; payload: ISelectItemViewModel }
  | { type: 'CHANGE_BINDING_PERIOD'; payload: ISelectItemViewModel }
  | {
      type: 'CHANGE_PRODUCT_FEATURES';
      featureName: string;
      payload: any;
    }
  | { type: 'SELECT_CATEGORY'; payload: ICategoriesViewModel }
  | { type: 'SELECT_PRODUCT_TYPE'; payload: IProductTypeViewModel }
  | { type: 'SELECT_BRAND'; payload: IBrandViewModel }
  | { type: 'SET_PRODUCT_CATEGORY'; payload: ICategoriesViewModel }
  | { type: 'SET_CATEGORIES'; payload: ICategoriesViewModel[] }
  | { type: 'SET_BRANDS'; payload: IBrandViewModel[] }
  | { type: 'SET_PRODUCT'; payload: IProductViewModel }
  | { type: 'SET_PRODUCT_FEATURES'; payload: IProductFeatureDataViewModel }
  | { type: 'SELECT_PRODUCT_VARIANT'; payload: EnumProductVariant };

const initialState: IProductFormInitialState = {
  id: 'new',
  productTypeId: 'new',
  features: [] as unknown as IProductFeatureDataViewModel[],
  name: '',
  comment: '',
  retailPrice: 0,
  unitPrice: 0,
  security: {
    id: '0',
    name: 'None',
  } as ISelectItemViewModel,
  isActive: true,
  url: '',
  fileModel: {} as unknown as IFileModel,
  imageFile: '',
  EAN: '',
  brandId: 'new',
  brands: null,
  brand: {} as unknown as IBrandViewModel,
  quantity: 1,
  paymentCycle: {
    id: '0',
    name: 'None',
  } as ISelectItemViewModel,
  cycleAlignment: {
    id: '0',
    name: 'None',
  } as ISelectItemViewModel,
  bindingPeriod: {
    id: '0',
    name: 'None',
  } as ISelectItemViewModel,
  productCategory: {} as unknown as ICategoriesViewModel,
  productCategories: null,
  productType: {} as unknown as IProductTypeViewModel,
  productTypes: [] as unknown as IProductTypeViewModel[],
  productFeatures: [] as unknown as IProductFeatureDataViewModel[],
  productFeatureList: [] as unknown as IProductFeatureViewModel[],
  productVariant: {} as unknown as EnumProductVariant,
  isDirty: false,
};

function stateReducer(state: any, action: Action): any {
  switch (action.type) {
    case 'RESET_FORM':
      return {
        ...initialState,
      };
    case 'SELECT_PRODUCT_VARIANT':
      return {
        ...state,
        productVariant: action.payload,
        isDirty: true,
      };
    case 'SET_PRODUCT':
      let security = SecurityData.find(
        (s: ISelectItemViewModel) => s.id === String(action.payload.security)
      );
      let paymentCycle = paymentCycleData.find(
        (s: ISelectItemViewModel) =>
          s.id === String(action.payload.paymentCycle)
      );
      let cycleAlignment = cycleAlignmentData.find(
        (s: ISelectItemViewModel) =>
          s.id === String(action.payload.cycleAlignment)
      );
      let bindingPeriod = bindingPeriodData.find(
        (s: ISelectItemViewModel) =>
          s.id === String(action.payload.bindingPeriod)
      );
      let category = {} as ICategoriesViewModel;
      if (state.productCategories) {
        category = state.productCategories.find(
          (c: ICategoriesViewModel) =>
            c.category === action.payload.productType?.productCategoryName
        );
      }
      let productTypes = [] as IProductTypeViewModel[];
      if (category?.productTypes?.length > 0) {
        productTypes = category.productTypes;
      }
      /*  let productFeatures = [] as IProductFeatureViewModel[];
      if (action.payload.features.length <= 0) {
        productFeatures =
          category?.productTypes?.find(
            (pt: IProductTypeViewModel) =>
              pt.name === action.payload.productType?.name
          )?.productFeatures ?? [];
      } */
      let newProduct = ConvertProductFeaturesData(action.payload);

      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        comment: action.payload.comment,
        unitPrice: action.payload.unitPrice,
        retailPrice: action.payload.retailPrice,
        security: security,
        isActive: action.payload.isActive,
        imageFile: action.payload.imageFile,
        EAN: action.payload.EAN,
        brand: action.payload.brand,
        brandId: action.payload.brandId,
        paymentCycle: paymentCycle,
        cycleAlignment: cycleAlignment,
        bindingPeriod: bindingPeriod,
        productCategory: category,
        productType: newProduct.productType,
        productTypes: productTypes,
        /*         productFeatureList:
          newProduct.productType.productFeatures ?? productFeatures, */
        quantity: action.payload.quantity,
      };
    case 'SET_CATEGORIES':
      return {
        ...state,
        productCategories: action.payload,
      };
    case 'SELECT_BRAND':
      return {
        ...state,
        brand: action.payload,
        brandId: action.payload.id,
      };
    case 'SET_BRANDS':
      return {
        ...state,
        brands: action.payload,
      };
    case 'SET_RETAIL_PRICE':
      return {
        ...state,
        retailPrice: action.payload,
        isDirty: true,
      };
    case 'SET_UNIT_PRICE':
      return {
        ...state,
        unitPrice: action.payload,
        isDirty: true,
      };
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.field]: action.payload,
        isDirty: true,
      };
    case 'CHANGE_SECURITY':
      return {
        ...state,
        security: action.payload,
        isDirty: true,
      };
    case 'CHANGE_PAYMENT_CYCLE':
      return {
        ...state,
        paymentCycle: action.payload,
        isDirty: true,
      };
    case 'CHANGE_CYCLE_ALIGNMENT':
      return {
        ...state,
        cycleAlignment: action.payload,
        isDirty: true,
      };
    case 'CHANGE_BINDING_PERIOD':
      return {
        ...state,
        bindingPeriod: action.payload,
        isDirty: true,
      };
    case 'CHANGE_PRODUCT_FEATURES':
      // Sikre at state.productFeatures er en array og klone den
      let clonedProductFeatures = Array.isArray(state.productFeatureList)
        ? _.cloneDeep(state.productFeatureList)
        : [];

      // Finn produktfunksjonen som skal oppdateres
      let featureToUpdate = clonedProductFeatures.find(
        (feature: IProductFeatureViewModel) =>
          feature.name === action.featureName
      );

      // Oppdater selectedDescription for funnet produktfunksjon
      if (featureToUpdate) {
        featureToUpdate.selectedDescription = action.payload.description;
      } else {
      }

      return {
        ...state,
        productFeatureList: clonedProductFeatures,
        isDirty: true,
      };

    case 'SELECT_CATEGORY':
      return {
        ...state,
        productCategory: action.payload,
        productTypes: action.payload.productTypes,
      };
    case 'SELECT_PRODUCT_TYPE':
      return {
        ...state,
        productType: action.payload,
        productFeatureList: action.payload.productFeatures,
      };
    case 'VALIDATE':
      try {
        schema.validateSync(state, { abortEarly: false });
        return { ...state, isValid: true };
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          // Log the entire error object
          //         console.log('Validation Error: ', error);

          // Log specific details
          //         console.log('Errors: ', error.errors); // Array of error messages
          //         console.log('Inner Errors: ', error.inner); // Detailed error objects for each field

          // If you want to log more specific details for each field
          error.inner.forEach((err) => {});
        } else {
          // If the error is not a yup.ValidationError, log it
          //          console.log('Unexpected Error: ', error);
        }

        return { ...state, isValid: false };
      }
    default:
      return state;
  }
}
interface FormErrors {
  name?: string;
  organizationNumber?: number;
  email?: string;
  webPage?: string;
  fixedHourlyRate?: number;
}

type FormProps = {
  categories: ICategoriesViewModel[];
  product: IProductViewModel;
  brands: IBrandViewModel[];
  setIsProducts: any;
};
export const ProductForm = ({
  categories,
  product,
  setIsProducts,
  brands,
}: FormProps) => {
  const { t } = useTranslation();

  /* INITIALIZATIONS */
  let navigate = useNavigate();
  const { productId } = useParams();
  // states
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [image, setImage] = useState<File | null>(null);
  const location = useLocation();

  useEffect(() => {}, [image]);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [base64String, setBase64String] = useState<string | null>(null);
  const [isAlreadyBilled, setIsAlreadyBilled] = useState<boolean>(false);
  const { height } = useWindowsDimension();

  // api
  let isEnabled = !state.type ? false : true;
  const { data: features } = useGet(
    rq.productTypeFeatures(state.type, isEnabled),
    isEnabled
  );
  const validProductId = productId || ''; // Use an empty string as a default if productId is undefined
  isEnabled = productId !== 'new' ? true : false;
  const { data: productData } = useGet(
    rq.product(productId || '', isEnabled),
    isEnabled
  );
  const putProduct = usePut(rqPost.product(validProductId, true), true);
  const postProduct = usePost(rqPost.product(validProductId, true), true);
  const [allBrands, setAllBrands] = useState([]);
  const [orgRetailPriceChanged, setOrgRetailPriceChanged] = useState(0);
  // API
  /* FUNCTIONS */
  const [errors, setErrors] = useState<FormErrors>({});

  const validateField = async (
    field: keyof IProductViewModel,
    value: string
  ) => {
    // console.log(`Validating field: ${field}, Value: ${value}`);

    try {
      await schema.validateAt(field, { [field]: value });
      setErrors((prev) => ({ ...prev, [field]: '' }));
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        setErrors((prev) => ({ ...prev, [field]: error.message }));
      }
    }
  };
  const Validate = () => {
    if (state.id === undefined) {
      return true;
    }
    let isValid = false;
    if (state?.name && state?.name.length <= 0) {
      isValid = true;
    }
    if (!state.productVariant?.name) {
      isValid = true;
    }
    if (!state.productCategory?.category) {
      isValid = true;
    }
    if (!state.productType?.name) {
      isValid = true;
    }
    if (!state.brand?.brandName) {
      isValid = true;
    }
    if (
      state.productVariant.name === 'Agreement' &&
      state.paymentCycle.name === paymentCycleData[0].name
    ) {
      isValid = true;
    }

    return isValid;
  };
  // handles
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleBlurSecurity = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let security = SecurityData.find(
      (s: ISelectItemViewModel) => s.name === event.target.value
    );

    if (security) {
      dispatch({
        type: 'CHANGE_SECURITY',
        payload: { id: security.id, name: security.name },
      });
    } else {
      // Håndter ugyldig tilfelle her, om nødvendig
    }
  };

  const HandleSelectProductVariant = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: EnumProductVariant | null
  ) => {
    if (newValue === null) {
      newValue = {} as EnumProductVariant; // Set to an empty object or a default state
    }
    dispatch({
      type: 'SELECT_PRODUCT_VARIANT',
      payload: newValue as EnumProductVariant,
    });
  };
  const handleSelectBrand = (
    event: React.SyntheticEvent,
    value: IBrandViewModel | null
  ) => {
    if (value === null) {
      value = {} as IBrandViewModel; // Set to an empty object or a default state
    }
    dispatch({
      type: 'SELECT_BRAND',
      payload: value as IBrandViewModel,
    });
  };
  const HandleSelectCategory = (
    event: React.SyntheticEvent,
    value: ICategoriesViewModel | null
  ) => {
    if (value === null) {
      value = {} as ICategoriesViewModel; // Set to an empty object or a default state
    }
    dispatch({
      type: 'SELECT_CATEGORY',
      payload: value as ICategoriesViewModel,
    });
  };
  const HandleSelectProductType = (
    event: React.SyntheticEvent,
    value: IProductTypeViewModel | null
  ) => {
    if (value === null) {
      value = {} as IProductTypeViewModel; // Set to an empty object or a default state
    }
    dispatch({
      type: 'SELECT_PRODUCT_TYPE',
      payload: value as IProductTypeViewModel,
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof IProductViewModel, value);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log('Field name:', name, 'Value:', value);

    dispatch({
      type: 'UPDATE_FIELD',
      field: name as keyof IProductFormInitialState,
      payload: value,
    });
    dispatch({ type: 'VALIDATE' });
  };
  const setFormDirty = () => {};

  const handleDuplicate = () => {
    setIsDuplicate(!isDuplicate);
  };

  const handleAddField = (e: any, field: string) => {
    e.preventDefault();
    // auto update a lest based on field
  };
  // USE EFFECTS
  useEffect(() => {
    // console.log('useEffect state: ', state);
  }, [state]);
  // SET PRODUCT
  useEffect(() => {
    // XXXXX akiver icAlreadyBilled
    if (productData?.isAlreadyBilled !== undefined) {
      if (productData.isAlreadyBilled === true) {
        setIsAlreadyBilled(true);
      } else {
        setIsAlreadyBilled(false);
      }
    }

    if (categories && !state.categories) {
      dispatch({
        type: 'SET_CATEGORIES',
        payload: categories,
      });
    }
    if (brands) {
      dispatch({
        type: 'SET_BRANDS',
        payload: brands,
      });
    }
    if (productData) {
      let variant = productData.productVariant;
      dispatch({
        type: 'SELECT_PRODUCT_VARIANT',
        payload: variant,
      });

      dispatch({
        type: 'SET_PRODUCT',
        payload: productData,
      });
      setOrgRetailPriceChanged(productData.retailPrice);
    }
  }, [productData, categories, state.categories, brands, state.brands]);

  // onsubmit

  const onSubmit = (e: any) => {
    e.preventDefault();
    let clonedState = _.cloneDeep(state);
    if (productId === 'new' || isDuplicate) {
      setIsDuplicate(false);
      let productToAdd = ConvertToAddProduct(clonedState, image, base64String);
      postProduct.mutate(productToAdd, {
        onSuccess: (res: any) => {
          dispatch({ type: 'RESET_FORM' });
          // Check if the source is from the drawer
        },
      });
    } else {
      let productToUpdate = ConvertToUpdateProduct(
        clonedState,
        image,
        base64String
      );
      putProduct.mutate(productToUpdate, {
        onSuccess: (res: any) => {},
      });
    }
  };
  const ProductVariant: EnumProductVariant[] = Object.values(
    EnumProductVariant
  ).filter((value): value is EnumProductVariant => typeof value === 'number');

  //console.log('Brands', brands);

  return (
    <Grid
      container
      display='flex'
      direction='row'
      spacing={0}
      sx={{
        p: 0,
        m: 0,
      }}
    >
      <Grid item xs={12}>
        <SearchFilter
          Icon={FeedRoundedIcon}
          Header={t('Products.ProductForm.productDetails')}
          gfx={
            <Grid container display={'flex'} flexDirection={'row'}>
              <Grid>
                {isDuplicate && (
                  <Typography
                    sx={{ pr: 1, fontSize: 12, color: 'warning.text' }}
                  >
                    {t('Products.ProductForm.duplicateActivated')}
                  </Typography>
                )}
              </Grid>
              <Box
                sx={{
                  mr: 1.7,
                  mt: 0.7,
                  width: 30 || 20,
                  height: 30 || 20,
                  backgroundColor: 'primary.light',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.64)',
                  '&:hover': {
                    filter: 'brightness(120%)',
                  },
                }}
                onClick={handleDuplicate}
              >
                <IconButton
                  sx={{
                    p: 0,
                    m: 0,
                    width: 30 - 5 || 18,
                    height: 30 - 5 || 18,
                    //color: 'secondary.light',
                    /*  '&:hover': {
                color: 'secondary.light'
              } */
                  }}
                  title={t('Products.ProductForm.duplicate')}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Grid>
          }
        />
      </Grid>

      <List
        sx={{ height: height - 248, backgroundColor: 'primary.main', pt: 2 }}
      >
        {/* PRODUCT VARIANT */}
        <Grid item xs={12}>
          <FormBox
            Icon={FeedRoundedIcon}
            title={t('Products.ProductForm.selectProductVariant')}
            FormBTN={
              <Grid item sx={{ width: 250, pr: 1 }}>
                <Autocomplete
                  fullWidth
                  disabled={isAlreadyBilled}
                  value={state.productVariant ?? EnumProductVariant.Investment} // Standard verdi
                  onChange={(
                    event: React.SyntheticEvent<Element, Event>, // Typen for event
                    newValue: EnumProductVariant | null // Typen for newValue, som kan være null
                  ) => HandleSelectProductVariant(event, newValue)}
                  options={ProductVariant}
                  getOptionLabel={(option: EnumProductVariant) => {
                    return (
                      enumEnumProductVariantToStringMapping[option] ||
                      t('Products.ProductForm.select')
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ ...ProductFormStyle.select }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
        </Grid>
        <Grid item xs={12}>
          <FormBox
            Icon={FeedRoundedIcon}
            title={t('Products.ProductForm.selectCategory')}
            FormBTN={
              <Grid item sx={{ width: 250, pr: 1 }}>
                <Autocomplete
                  fullWidth
                  value={state.productCategory || ({} as ICategoriesViewModel)}
                  onChange={HandleSelectCategory}
                  options={
                    state.productCategories || ([] as ICategoriesViewModel[])
                  }
                  getOptionLabel={(option: ICategoriesViewModel) => {
                    return (
                      option.category ?? `${t('Products.ProductForm.select')}`
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ ...ProductFormStyle.select }}
                      {...params}
                      variant='standard'
                    />
                  )}
                />
              </Grid>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
        </Grid>
        {/* PRODUCT TYPE */}
        <FormBox
          Icon={FeedRoundedIcon}
          title={t('Products.ProductForm.selectProductType')}
          FormBTN={
            <Grid item sx={{ width: 250, pr: 1 }}>
              <Autocomplete
                fullWidth
                value={state.productType}
                onChange={HandleSelectProductType}
                options={state.productTypes || ([] as IProductTypeViewModel[])}
                getOptionLabel={(option: IProductTypeViewModel) => {
                  return option.name ?? `${t('Products.ProductForm.select')}`;
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ ...ProductFormStyle.select }}
                    {...params}
                    variant='standard'
                  />
                )}
              />
            </Grid>
          }
        />
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
        </Grid>
        {/* BASIC PRODUCT INFORMATION */}
        <FormBox
          Icon={FeedRoundedIcon}
          title={t('Products.ProductForm.basicProductInformation')}
        />
        <Grid
          container
          display={'flex'}
          flexDirection={'row'}
          flexGrow={1}
          sx={{ mt: 1 }}
        >
          <Stack direction='row' flexGrow={1} spacing={0} sx={{}}>
            <Grid
              item
              xs={3}
              sx={
                {
                  //width: 264,
                  // height: 264,
                }
              }
            >
              <ImageUploader
                width={264}
                height={264}
                setImage={setImage}
                setBase64String={(str) => {
                  if (typeof str === 'string' || str === null) {
                    setBase64String(str);
                  }
                }}
                setFormDirty={setFormDirty}
                base64String={base64String}
                previewImage={product?.url || MissingPhoto.IMAGE_URL}
              />
              <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                {t('Products.ProductForm.dragDropReplacePicture')}
              </Typography>
            </Grid>
            <Grid
              container
              display={'flex'}
              flexDirection={'row'}
              sx={{ flexGrow: 2, pl: 2, pr: 1 }}
            >
              {/* NAME */}
              <Grid item xs={8} sx={{ height: 72, pr: 2 }}>
                <TextField
                  name='name'
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.name')}
                    </Typography>
                  }
                  value={state.name}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              {/* BRAND */}
              <Grid item xs={4} sx={{ height: 72 }}>
                <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                  <IconButton
                    onClick={(e) => {
                      handleAddField(e, 'brand');
                    }}
                    sx={{ ...ProductFormStyle.addIcon }}
                  >
                    <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                  </IconButton>

                  <Autocomplete
                    fullWidth
                    value={state.brand || ({} as IBrandViewModel)}
                    onChange={handleSelectBrand}
                    options={state.brands || ([] as IBrandViewModel[])}
                    getOptionLabel={(option: IBrandViewModel) => {
                      return (
                        option.brandName ??
                        `${t('Products.ProductForm.select')}`
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ ...ProductFormStyle.select, pt: 2 }}
                        {...params}
                        variant='standard'
                      />
                    )}
                  />
                </Stack>
              </Grid>
              {/* COMMENT */}
              <Grid item xs={12} sx={{}}>
                <TextField
                  sx={{
                    ...ProductFormStyle.textField,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  name={'comment'}
                  onFocus={handleFocus}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.comment')}
                    </Typography>
                  }
                  multiline={true}
                  minRows={3}
                  maxRows={3}
                  value={state.comment}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  /*                    error={!!errors.comment}
                  helperText={errors.comment} */
                />
              </Grid>
              {/* SECURITY */}
              <Grid item xs={3} sx={{ height: 72, pr: 2 }}>
                <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                  <IconButton
                    onClick={(e) => {
                      handleAddField(
                        e,
                        `${t('Products.ProductForm.security')}`
                      );
                    }}
                    sx={{ ...ProductFormStyle.addIcon }}
                  >
                    <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                  <Autocomplete
                    title={t('Products.ProductForm.security')}
                    fullWidth
                    value={state.security}
                    onChange={(
                      event: React.SyntheticEvent<Element, Event>,
                      value: ISelectItemViewModel | null,
                      reason: string
                    ) => {
                      if (value) {
                        //console.log('Selected security: ', value);
                        dispatch({
                          type: 'CHANGE_SECURITY',
                          payload: value,
                        });
                      }
                    }}
                    options={SecurityData}
                    getOptionLabel={(option: ISelectItemViewModel) => {
                      //console.log(option); // For å se hva som blir behandlet
                      return (
                        option.name ??
                        `${t('Products.ProductForm.securitySelect')}`
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        onBlur={handleBlurSecurity}
                        sx={{ ...ProductFormStyle.select }}
                        {...params}
                        label={
                          <Typography sx={{ ...ProductFormStyle.SelectHeader }}>
                            {t('Products.ProductForm.security')}
                          </Typography>
                        }
                        variant='standard'
                      />
                    )}
                  />
                </Stack>
              </Grid>
              {/* UNIT PRICE */}
              <Grid item xs={2} sx={{ height: 72, pr: 2 }}>
                <TextField
                  name={t('Products.ProductForm.unitPrice')}
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.unitPrice')}
                    </Typography>
                  }
                  inputProps={{
                    style: { textAlign: 'right' },
                    type: 'number',
                  }}
                  InputProps={{
                    // endAdornment: (
                    //   <InputAdornment position='start'>.00</InputAdornment>
                    // ),
                    sx: {
                      // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                        {
                          webkitAppearance: 'none',
                          margin: 0,
                        },
                      // Skjuler pilene for Firefox
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    },
                  }}
                  value={state.unitPrice}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_UNIT_PRICE',
                      payload: parseFloat(
                        parseFloat(e.target.value).toFixed(2)
                      ),
                    })
                  }
                  onBlur={handleBlur}
                  /*  error={!!errors.comment}
                  helperText={errors.comment} */
                />
              </Grid>
              {/* RETAIL PRICE */}
              <Grid item xs={2} sx={{ height: 72, pr: 2 }}>
                <TextField
                  name={t('Products.ProductForm.retailPrice')}
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.retailPrice')}
                    </Typography>
                  }
                  inputProps={{
                    style: { textAlign: 'right' },
                    type: 'number',
                  }}
                  InputProps={{
                    // endAdornment: (
                    //   <InputAdornment position='start'>.00</InputAdornment>
                    // ),
                    sx: {
                      // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                        {
                          webkitAppearance: 'none',
                          margin: 0,
                        },
                      // Skjuler pilene for Firefox
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    },
                  }}
                  value={state.retailPrice}
                  onChange={(e) => {
                    dispatch({
                      type: 'SET_RETAIL_PRICE',
                      payload: parseFloat(
                        parseFloat(e.target.value).toFixed(2)
                      ),
                    });
                  }}
                  onBlur={handleBlur}
                  /*  error={!!errors.comment}
                  helperText={errors.comment} */
                />
              </Grid>
              {/* QUNATITY */}
              <Grid item xs={2} sx={{ height: 72, pr: 2 }}>
                <TextField
                  name={'quantity'}
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.quantity')}
                    </Typography>
                  }
                  inputProps={{
                    style: { textAlign: 'right' },
                  }}
                  InputProps={{
                    sx: {
                      // Skjuler pilene for Webkit-baserte nettlesere som Chrome og Safari
                      '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
                        {
                          webkitAppearance: 'none',
                          margin: 0,
                        },
                      // Skjuler pilene for Firefox
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    },
                  }}
                  value={state.quantity}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  /*  error={!!errors.comment}
                  helperText={errors.comment} */
                />
              </Grid>
              {/* EAN */}
              <Grid item xs={3} sx={{ height: 72 }}>
                <TextField
                  name={t('Products.ProductForm.ean')}
                  onFocus={handleFocus}
                  sx={{ ...ProductFormStyle.textField }}
                  label={
                    <Typography sx={{ ...ProductFormStyle.textFieldLabel }}>
                      {t('Products.ProductForm.ean')}
                    </Typography>
                  }
                  value={state.EAN}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  /*  error={!!errors.comment}
                  helperText={errors.comment} */
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        {state.productVariant.name === 'Agreement' && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormBox
                Icon={FeedRoundedIcon}
                title={t('Products.ProductForm.cyclePaymentAgreement')}
                FormBTN={null}
              />
            </Grid>
            <Grid item xs={12} sx={{ flexDirection: 'row', mr: -1 }}>
              <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
              <Grid container display='flex' direction='row' spacing={0}>
                {/* PAYMENT CYCLE */}
                <Grid item xs={4} sx={{ height: 72, pr: 2 }}>
                  <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                    <IconButton
                      onClick={(e) => {
                        handleAddField(
                          e,
                          `${t('Products.ProductForm.paymentCycle')}`
                        );
                      }}
                      sx={{ ...ProductFormStyle.addIcon }}
                    >
                      <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Autocomplete
                      title={t('Products.ProductForm.paymentCycle')}
                      fullWidth
                      value={state.paymentCycle}
                      onChange={(
                        event: React.SyntheticEvent<Element, Event>,
                        value: ISelectItemViewModel | null,
                        reason: string
                      ) => {
                        if (value) {
                          //console.log('Selected security: ', value);
                          dispatch({
                            type: 'CHANGE_PAYMENT_CYCLE',
                            payload: value,
                          });
                        }
                      }}
                      options={paymentCycleData}
                      getOptionLabel={(option: ISelectItemViewModel) => {
                        //console.log(option); // For å se hva som blir behandlet
                        return option.name ?? 'None';
                      }}
                      renderInput={(params) => (
                        <TextField
                          onBlur={handleBlurSecurity}
                          sx={{ ...ProductFormStyle.select }}
                          {...params}
                          label={
                            <Typography
                              sx={{ ...ProductFormStyle.SelectHeader }}
                            >
                              {t('Products.ProductForm.paymentCycle')}
                            </Typography>
                          }
                          variant='standard'
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                {/* CYCLE ALIGNMENT */}
                <Grid item xs={4} sx={{ height: 72, pr: 2 }}>
                  <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                    <IconButton
                      onClick={(e) => {
                        handleAddField(
                          e,
                          `${t('Products.ProductForm.cycleAlignment')}`
                        );
                      }}
                      sx={{ ...ProductFormStyle.addIcon }}
                    >
                      <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Autocomplete
                      title={t('Products.ProductForm.cycleAlignment')}
                      fullWidth
                      value={state.cycleAlignment}
                      onChange={(
                        event: React.SyntheticEvent<Element, Event>,
                        value: ISelectItemViewModel | null,
                        reason: string
                      ) => {
                        if (value) {
                          //console.log('Selected security: ', value);
                          dispatch({
                            type: 'CHANGE_CYCLE_ALIGNMENT',
                            payload: value,
                          });
                        }
                      }}
                      options={cycleAlignmentData}
                      getOptionLabel={(option: ISelectItemViewModel) => {
                        //console.log(option); // For å se hva som blir behandlet
                        return option.name ?? 'None';
                      }}
                      renderInput={(params) => (
                        <TextField
                          onBlur={handleBlurSecurity}
                          sx={{ ...ProductFormStyle.select }}
                          {...params}
                          label={
                            <Typography
                              sx={{ ...ProductFormStyle.SelectHeader }}
                            >
                              {t('Products.ProductForm.cycleAlignment')}
                            </Typography>
                          }
                          variant='standard'
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                {/* BINDING PERIOD */}
                <Grid item xs={4} sx={{ height: 72 }}>
                  <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                    <IconButton
                      onClick={(e) => {
                        handleAddField(
                          e,
                          `${t('Products.ProductForm.bindingPeriod')}`
                        );
                      }}
                      sx={{ ...ProductFormStyle.addIcon }}
                    >
                      <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                    </IconButton>
                    <Autocomplete
                      title={t('Products.ProductForm.bindingPeriod')}
                      fullWidth
                      value={state.bindingPeriod}
                      onChange={(
                        event: React.SyntheticEvent<Element, Event>,
                        value: ISelectItemViewModel | null,
                        reason: string
                      ) => {
                        if (value) {
                          //console.log('Selected security: ', value);
                          dispatch({
                            type: 'CHANGE_BINDING_PERIOD',
                            payload: value,
                          });
                        }
                      }}
                      options={bindingPeriodData}
                      getOptionLabel={(option: ISelectItemViewModel) => {
                        //console.log(option); // For å se hva som blir behandlet
                        return option.name ?? 'None';
                      }}
                      renderInput={(params) => (
                        <TextField
                          onBlur={handleBlurSecurity}
                          sx={{ ...ProductFormStyle.select }}
                          {...params}
                          label={
                            <Typography
                              sx={{ ...ProductFormStyle.SelectHeader }}
                            >
                              {t('Products.ProductForm.bindingPeriod')}
                            </Typography>
                          }
                          variant='standard'
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
          </>
        )}
        <Grid item xs={12}>
          <Divider sx={{ mt: 2, mb: 2 }} variant='inset' />
        </Grid>
        {/* PRODUCT PRODUCT FEATURES */}
        <FormBox
          Icon={FeedRoundedIcon}
          title={t('Products.ProductForm.productFeatures')}
        />
        <Grid container direction='row' sx={{}}>
          {state.productFeatureList?.map(
            (feature: IProductFeatureViewModel, index: number) => (
              <Grid
                item
                xs={4}
                sx={{ height: 74, ml: -0.5, pl: 1, pr: 1 }}
                key={index}
              >
                <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
                  <IconButton
                    onClick={(e) => {
                      handleAddField(
                        e,
                        `${t('Products.ProductForm.productFeatures')}`
                      );
                    }}
                    sx={{ ...ProductFormStyle.addIcon }}
                  >
                    <AddBoxRoundedIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                  <Autocomplete
                    title={t('Products.ProductForm.productFeatures')}
                    fullWidth
                    value={feature.selectedDescription ?? 'Select X'}
                    onChange={(
                      event: React.SyntheticEvent<Element, Event>,
                      value: any | null,
                      reason: string
                    ) => {
                      if (value) {
                        //console.log('Selected security: ', value);
                        dispatch({
                          type: 'CHANGE_PRODUCT_FEATURES',
                          featureName: feature.name,
                          payload: value,
                        });
                      }
                    }}
                    options={feature.productFeatureData}
                    getOptionLabel={(option: any) => {
                      //console.log(option); // For å se hva som blir behandlet
                      return (
                        option.description ??
                        (feature.selectedDescription || 'select')
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        // onBlur={handleBlurSecurity}
                        sx={{ ...ProductFormStyle.select }}
                        {...params}
                        label={
                          <Typography sx={{ ...ProductFormStyle.SelectHeader }}>
                            {feature.name}
                          </Typography>
                        }
                        variant='standard'
                      />
                    )}
                  />
                </Stack>
              </Grid>
            )
          )}
        </Grid>
      </List>
      {/*  FOOTER */}
      <Paper
        variant='elevation3'
        sx={{
          p: 2,
          width: '100%',
          height: 64,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid sx={{ flexGrow: 1 }}></Grid>
          {state.isDirty &&
            productId !== 'new' &&
            orgRetailPriceChanged !== state.retailPrice && (
              <Grid sx={{ pr: 2, pt: 1.5 }}>
                <Typography
                  sx={{
                    color: 'warning.text',
                    fontSize: 18,
                    letterSpacing: 1,
                  }}
                >
                  {t('Products.ProductForm.updateMessage')}
                </Typography>
              </Grid>
            )}
          <Grid item>
            <Button
              type='submit'
              variant='contained'
              disabled={
                Validate() || putProduct.isLoading || postProduct.isLoading
              }
              onClick={(e) => onSubmit(e)}
            >
              {isDuplicate || productId === 'new'
                ? t('Products.ProductForm.createProduct', {
                    name:
                      state.productVariant?.name ||
                      t('Products.ProductForm.defaultProductName'),
                  })
                : t('Products.ProductForm.updateProduct', {
                    name:
                      state.productVariant?.name ||
                      t('Products.ProductForm.defaultProductName'),
                  })}
              {/*  {putProduct.isLoading || postProduct.isLoading
            ? t("Products.ProductForm.submitting")
            : t("Products.ProductForm.submit")} */}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
