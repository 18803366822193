import { IRateViewModel, IWorkRoleViewModel } from 'data/atoms/atomConfig';
import { ITicketCommunicationData } from 'pages/Tickets/TicketCommunications';
import { findHours } from './findHours';

export const calculateTicketPostBilling = (
  state: ITicketCommunicationData, // ITicketCommunicationData,
  rates: IRateViewModel[],
  workRole: IWorkRoleViewModel | null,
  fixedHourlyRate: number
) => {
  let ticketPostBilling = {
    id: '00000000-0000-0000-0000-000000000000',
    ticketPostId: state.ticketPost.id ?? '00000000-0000-0000-0000-000000000000',
    isBillable: state.isBillable,
    nightHours: 0,
    nightCost: 0,
    nightBillableValue: 0,
    workHours: 0,
    workCost: 0,
    workBillableValue: 0,
    midDayHours: 0,
    midDayCost: 0,
    midDayBillableValue: 0,
    eveningHours: 0,
    eveningCost: 0,
    eveningBillableValue: 0,
    notBillableHours: 0,
    notBillableCost: 0,
    notBillableBillingValue: 0,
  };

  if (!workRole && !state.startDate && !state.endDate) {
    return ticketPostBilling;
  }
  // console.log(
  //   'calculateTicketPostBilling - x',
  //   state.startDate,
  //   state.endDate,
  //   rates
  // );

  const totalNotBillableHours = findHours(
    'notBillable',
    state.startDate,
    state.endDate,
    rates
  );

  let workRate = state.isInHouse
    ? workRole?.inHouseHourlyRate || 0
    : workRole?.onSiteHourlyRate || 0;
  if (fixedHourlyRate > 0) {
    workRate = fixedHourlyRate;
  }

  let workCost = state.isInHouse
    ? workRole?.inHouseHourlyRateCost || 0
    : workRole?.onSiteHourlyRateCost || 0;
  if (fixedHourlyRate > 0) {
    workCost = 600; // xxx denne nå endres til faktisk utgift på den som arbeider
  }

  if (state.isBillable && state.overTime) {
    ticketPostBilling.nightHours = findHours(
      'night',
      state.startDate,
      state.endDate,
      rates
    );
    // console.log('ticketPostBilling.nightHours', ticketPostBilling.nightHours);
    ticketPostBilling.workHours = findHours(
      'work',
      state.startDate,
      state.endDate,
      rates
    );
    // console.log('ticketPostBilling.workHours', ticketPostBilling.workHours);
    ticketPostBilling.midDayHours = findHours(
      'midDay',
      state.startDate,
      state.endDate,
      rates
    );
    // console.log('ticketPostBilling.midDayHours', ticketPostBilling.midDayHours);
    ticketPostBilling.eveningHours = findHours(
      'evening',
      state.startDate,
      state.endDate,
      rates
    );
    /* console.log(
    'ticketPostBilling.eveningHours',
    ticketPostBilling.eveningHours
    ); */
    // NIGHT

    let overtimeCost = state.overTime
      ? ticketPostBilling.nightHours * (workCost * 2)
      : workCost;
    ticketPostBilling.nightCost = overtimeCost;

    overtimeCost = state.overTime
      ? ticketPostBilling.nightHours * (workRate * 2)
      : workRate;
    ticketPostBilling.nightBillableValue = overtimeCost;

    // WORK
    overtimeCost = state.overTime
      ? ticketPostBilling.workHours * (workCost * 1)
      : workCost;
    ticketPostBilling.workCost = overtimeCost;

    overtimeCost = state.overTime
      ? ticketPostBilling.workHours * (workRate * 1)
      : workRate;
    ticketPostBilling.workBillableValue = overtimeCost;

    // MID DAY
    overtimeCost = state.overTime
      ? ticketPostBilling.midDayHours * (workCost * 1.5)
      : workCost;
    ticketPostBilling.midDayCost = overtimeCost;

    overtimeCost = state.overTime
      ? ticketPostBilling.midDayHours * (workRate * 1.5)
      : workRate;
    ticketPostBilling.midDayBillableValue = overtimeCost;

    // EVENING
    overtimeCost = state.overTime
      ? ticketPostBilling.eveningHours * (workCost * 1.5)
      : workCost;
    ticketPostBilling.eveningCost = overtimeCost;

    overtimeCost = state.overTime
      ? ticketPostBilling.eveningHours * (workRate * 1.5)
      : workRate;
    ticketPostBilling.eveningBillableValue = overtimeCost;
  } else if (state.isBillable) {
    ticketPostBilling.workHours = totalNotBillableHours;
    ticketPostBilling.workBillableValue = totalNotBillableHours * workRate;
    ticketPostBilling.workCost = totalNotBillableHours * workCost;
  } else {
    ticketPostBilling.notBillableHours = totalNotBillableHours;
    ticketPostBilling.notBillableBillingValue =
      totalNotBillableHours * workRate;
    ticketPostBilling.notBillableCost = totalNotBillableHours * workCost;
  }
  // console.log('ticketPostBilling', ticketPostBilling);
  return ticketPostBilling;
};
