import React, { useEffect, useReducer, useState } from 'react';
import {
  Grid,
  List,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Typography,
  Paper,
  Stack,
} from '@mui/material';
import { rq, rqPost } from 'http/apiRoutes';
import { useGet, usePost } from 'http/useInnovit';
import { useTranslation } from 'react-i18next';

import { PDFFile } from 'components/ui/Billing/PDFFile';
import { pdf } from '@react-pdf/renderer';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';

import {
  IBillingCompanyProductViewModel,
  IInvoiceAddViewModel,
  IInvoiceViewModel,
  sxSubHeader,
  sxSubValue,
} from '../../Interfaces/BillingConfig';
import { ICompanyViewModel } from 'Interfaces/ICompaniesConfig';
import { ITicketBillingViewModel } from 'Interfaces/ITicketConfig';
import SingleBillingPostView, {
  calculateSupportInvoiceValue,
} from 'components/ui/SingleBillingPostView';
import useWindowsDimension from 'functions/useWindowsDimension';
import { SearchFilter } from 'components/ui/SearchFilter';
import { CompanyInfoView } from 'components/ui/CompanyInfoView';
import SingleBillingTicketView from 'components/ui/SingleBillingTicketView';
import SingleBillingInvestmentView from 'components/ui/SingleBillingInvestmentView';
import SingleBillingAgreementView from 'components/ui/SingleBillingAgreementView';

export type BillingCompanyViewModel = {
  id: string;
  company: ICompanyViewModel;
  cost: number;
  agreementCost: number;
  agreementInvoiceValue: number;
  supportCost: number;
  supportInvoiceValue: number;
  investmentCost: number;
  investmentInvoiceValue: number;
  otherCost: number;
  otherInvoiceValue: number;
  agreementProductList: IBillingCompanyProductViewModel[];
  investmentProductList: IBillingCompanyProductViewModel[];
  ticketBillingList: ITicketBillingViewModel[];
  otherProductList: IBillingCompanyProductViewModel[];
  isBilled: boolean;
  invoiceNo: string;
  selectedBillingPost?: BillingCompanyViewModel;
  totalInvoice: number;
};

export type BillingListCompanyViewModel = {
  numberOfBillingPost: number;
  billingPost: BillingCompanyViewModel[];
};

const initialState: BillingCompanyViewModel = {
  id: '',
  company: {} as ICompanyViewModel,
  cost: 0,
  agreementCost: 0,
  agreementInvoiceValue: 0,
  supportCost: 0,
  supportInvoiceValue: 0,
  investmentCost: 0,
  investmentInvoiceValue: 0,
  otherCost: 0,
  otherInvoiceValue: 0,
  agreementProductList: [],
  investmentProductList: [],
  ticketBillingList: [],
  otherProductList: [],
  isBilled: false,
  invoiceNo: '',
  selectedBillingPost: undefined,
  totalInvoice: 0,
};

const billingPostReducer = (
  state: BillingCompanyViewModel,
  action: any
): BillingCompanyViewModel => {
  switch (action.type) {
    case 'SET_BILLING_POSTS':
      return { ...state, ...action.payload[0] };
    case 'SELECT_BILLING_POST':
      return { ...state, selectedBillingPost: action.payload };
    case 'SET_AGREEMENT_PRODUCTS':
      return { ...state, agreementProductList: action.payload };
    case 'SET_INVESTMENT_PRODUCTS':
      return { ...state, investmentProductList: action.payload };
    case 'SET_TICKET_BILLING_LIST':
      return { ...state, ticketBillingList: action.payload };
    case 'SET_OTHER_BILLING_LIST':
      return { ...state, otherProductList: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

const calculateAgreementProducts = (
  agreementProductList: IBillingCompanyProductViewModel[]
) => {
  const groupedProducts: {
    [key: string]: {
      product: IBillingCompanyProductViewModel;
      totalQuantity: number;
      totalCost: number;
      monthsNotBilled: number;
    };
  } = {};

  agreementProductList.forEach((product) => {
    const key = product.product.name; // Bruk `product.product.name` som nøkkel for gruppering
    const lastBilledDate = product.lastBilled
      ? new Date(product.lastBilled)
      : null;
    const currentDate = new Date();
    const monthsNotBilled = lastBilledDate
      ? Math.max(
          (currentDate.getFullYear() - lastBilledDate.getFullYear()) * 12 +
            currentDate.getMonth() -
            lastBilledDate.getMonth(),
          0
        )
      : 1; // Hvis `lastBilled` er null, antar vi 1 måned ikke fakturert

    if (groupedProducts[key]) {
      groupedProducts[key].totalQuantity += product.quantity;
      groupedProducts[key].totalCost += product.unitPrice * product.quantity;
      groupedProducts[key].monthsNotBilled = Math.max(
        groupedProducts[key].monthsNotBilled,
        monthsNotBilled
      );
    } else {
      groupedProducts[key] = {
        product,
        totalQuantity: product.quantity,
        totalCost: product.unitPrice * product.quantity * monthsNotBilled,
        monthsNotBilled,
      };
    }

    // Logg hvert produkt og hvordan det blir gruppert
    console.log(`FUCK: Produkt-ID: ${product.id}`);
    console.log(`FUCK: Produktnavn: ${product.product.name}`);
    console.log(`FUCK: Antall ikke fakturerte måneder: ${monthsNotBilled}`);
    console.log(
      `FUCK: Oppdatert total mengde: ${groupedProducts[key].totalQuantity}`
    );
    console.log(
      `FUCK: Oppdatert total kostnad: ${groupedProducts[key].totalCost}`
    );
    console.log(`FUCK: ------------`);
  });

  // Konverter til array for å sende til `SingleBillingAgreementView`
  const result = Object.values(groupedProducts).map((group) => ({
    ...group.product,
    quantity: group.totalQuantity,
    total: group.totalCost,
    monthsNotBilled: group.monthsNotBilled,
  }));

  // Logg resultatet før retur
  console.log('FUCK: Beregnede grupperte produkter:', result);

  return result;
};

export const BillingView: React.FC = () => {
  // INITIALIZATION
  const { height } = useWindowsDimension();
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(billingPostReducer, initialState);
  const { data: billingPostData } = useGet(rq.billing(true), true);
  const postInvoiceData = usePost(rqPost.Invoice(true), true);
  const [confirmInvoice, setConfirmInvoice] = useState(false);

  // Håndter første knapp for fakturering
  const handleFirstInvoiceClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setConfirmInvoice(true);
  };

  // Håndter bekreftelse og utfør fakturering
  const handleConfirmInvoice = (e: React.MouseEvent) => {
    e.preventDefault();
    // Her kan du plassere logikken for å faktisk utføre faktureringen
    onSubmit(e);
    setConfirmInvoice(false); // Skjul bekreftelsesknappen igjen etter utført fakturering
  };

  const defaultFilters = [
    'agreementProduct',
    'investmentProduct',
    'supportTicket',
    'otherProduct',
  ];
  const [selectedFilters, setSelectedFilters] =
    useState<string[]>(defaultFilters);

  const supportInvoiceValue = calculateSupportInvoiceValue(
    state.selectedBillingPost?.ticketBillingList || []
  );

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (billingPostData?.billingPost) {
      dispatch({
        type: 'SET_BILLING_POSTS',
        payload: billingPostData.billingPost,
      });
      dispatch({
        type: 'SET_AGREEMENT_PRODUCTS',
        payload: billingPostData.billingPost[0]?.agreementProductList || [],
      });
      dispatch({
        type: 'SET_INVESTMENT_PRODUCTS',
        payload: billingPostData.billingPost[0]?.investmentProductList || [],
      });
      dispatch({
        type: 'SET_TICKET_BILLING_LIST',
        payload: billingPostData.billingPost[0]?.ticketBillingList || [],
      });
    }
  }, [billingPostData]);

  const handleBillingPostClick = (billingPost: BillingCompanyViewModel) => {
    dispatch({ type: 'SELECT_BILLING_POST', payload: billingPost });
  };

  const onSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    const invoice: IInvoiceAddViewModel = {
      companyId: state.selectedBillingPost?.company.id || '',

      agreementCost: selectedFilters.includes('agreementProduct')
        ? state.selectedBillingPost?.agreementCost || 0
        : 0,

      agreementInvoiceValue: selectedFilters.includes('agreementProduct')
        ? state.selectedBillingPost?.agreementInvoiceValue || 0
        : 0,

      agreementProductList: selectedFilters.includes('agreementProduct')
        ? state.selectedBillingPost?.agreementProductList || []
        : [],

      investmentCost: selectedFilters.includes('investmentProduct')
        ? state.selectedBillingPost?.investmentCost || 0
        : 0,

      investmentInvoiceValue: selectedFilters.includes('investmentProduct')
        ? state.selectedBillingPost?.investmentInvoiceValue || 0
        : 0,

      investmentProductList: selectedFilters.includes('investmentProduct')
        ? state.selectedBillingPost?.investmentProductList || []
        : [],

      otherCost: selectedFilters.includes('otherProduct')
        ? state.selectedBillingPost?.otherCost || 0
        : 0,

      otherInvoiceValue: selectedFilters.includes('otherProduct')
        ? state.selectedBillingPost?.otherInvoiceValue || 0
        : 0,

      otherProductList: selectedFilters.includes('otherProduct')
        ? state.selectedBillingPost?.otherProductList || []
        : [],

      supportCost: selectedFilters.includes('supportTicket')
        ? state.selectedBillingPost?.supportCost || 0
        : 0,

      supportInvoiceValue: selectedFilters.includes('supportTicket')
        ? supportInvoiceValue || 0
        : 0,

      ticketBillingList: selectedFilters.includes('supportTicket')
        ? state.selectedBillingPost?.ticketBillingList || []
        : [],
    };
    console.log('XXX Invoice:', invoice);
  /*   postInvoiceData.mutate(invoice, {
      onSuccess: async (res: any) => {
        const parsedRes = typeof res === 'string' ? JSON.parse(res) : res;
        console.log('Invoice created:', parsedRes);
        const updatedBillingPost = {
          ...state.selectedBillingPost!,
          invoiceNo: parsedRes.invoiceNo,
        };
        const blob = await pdf(<PDFFile data={updatedBillingPost} />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${res.invoiceNo} invoice.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        dispatch({ type: 'RESET' });
      },
    }); */
  };

  const groupedAgreementProducts = calculateAgreementProducts(
    state.selectedBillingPost?.agreementProductList || []
  );
  const filteredAgreementProducts = selectedFilters.includes('agreementProduct')
    ? groupedAgreementProducts
    : [];
  const filteredTicketBillingList = selectedFilters.includes('supportTicket')
    ? state.selectedBillingPost?.ticketBillingList || []
    : [];
  const filteredInvestmentProductList = selectedFilters.includes(
    'investmentProduct'
  )
    ? state.selectedBillingPost?.investmentProductList || []
    : [];
  const filteredOtherProductList = selectedFilters.includes('otherProduct')
    ? state.selectedBillingPost?.otherProductList || []
    : [];

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      {/* Left side - Agreement and Investment products */}
      <Grid
        item
        xs={6}
        sx={{ pr: 0.5, m: 0, display: 'flex', flexDirection: 'column' }}
      >
        <SearchFilter
          Icon={RequestQuoteRoundedIcon}
          Header={t('Billing.billingView.unbilledPost')}
        />

        <List
          sx={{
            height: Math.ceil(height - 250),
            backgroundColor: 'primary.back',
          }}
        >
          {billingPostData?.billingPost.map(
            (billingPost: BillingCompanyViewModel) => (
              <Grid
                sx={{ m: 1 }}
                key={billingPost.id}
                onClick={() => {
                  handleBillingPostClick(billingPost);
                  setIsDisabled(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <SingleBillingPostView billingPost={billingPost} />
              </Grid>
            )
          )}
        </List>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'primary.main',
          }}
        >
          {/*pagination */}
        </Paper>
      </Grid>

      {/* Right side - Ticket Billing List */}
      <Grid
        item
        xs={6}
        sx={{ p: 0, m: 0, pl: 0.5, display: 'flex', flexDirection: 'column' }}
      >
        <SearchFilter
          Icon={RequestQuoteRoundedIcon}
          Header={t('Billing.billingView.selectedBillingPost')}
        />
        <CompanyInfoView company={state.company ? state.company : null} />
        <List
          sx={{
            height: Math.ceil(height - 500),
            backgroundColor: 'primary.back',
            p: 1,
            overflow: 'auto',
          }}
        >
          {filteredAgreementProducts.map((agreement) => (
            <SingleBillingAgreementView
              key={agreement.id}
              agreement={agreement}
            />
          ))}
          {filteredTicketBillingList.map((ticket) => (
            <SingleBillingTicketView key={ticket.id} ticket={ticket} />
          ))}
          {filteredInvestmentProductList.map((investment) => (
            <SingleBillingInvestmentView
              key={investment.id}
              investment={investment}
            />
          ))}
          {/*  {filteredOtherProductList.map((other) => (
            <SingleBillingOtherView key={other.id} other={other} />
          ))} */}
        </List>

        <Paper
          variant='elevation3'
          sx={{
            p: 2,

            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: 156,
            }}
          >
            <Grid
              container
              display='flex'
              flexDirection='row'
              sx={{ m: 0, pl: 1 }}
            >
              {/* Agreement Section */}
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>
                  {t('Billing.billingView.agreementSubHeader')}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {(selectedFilters.includes('agreementProduct')
                    ? state.selectedBillingPost?.agreementInvoiceValue ?? 0
                    : 0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>

              {/* Support Section */}
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>
                  {t('Billing.billingView.supportSubHeader')}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {(selectedFilters.includes('supportTicket')
                    ? supportInvoiceValue ?? 0
                    : 0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>

              {/* Investment Section */}
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>
                  {t('Billing.billingView.investmentSubHeader')}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {(selectedFilters.includes('investmentProduct')
                    ? state.selectedBillingPost?.investmentInvoiceValue ?? 0
                    : 0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>

              {/* Other Section */}
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>
                  {t('Billing.billingView.otherSubHeader')}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {(selectedFilters.includes('otherProduct')
                    ? state.selectedBillingPost?.otherInvoiceValue ?? 0
                    : 0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>

              {/* Total Section */}
              <Grid item xs={6} sx={{}}></Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubHeader}>Total</Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography sx={sxSubValue}>
                  {state.totalInvoice.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              height: 48,
              backgroundColor: 'primary.back',
            }}
          >
            <ToggleButtonGroup
              size='small'
              value={selectedFilters}
              onChange={(event, newFilters) => setSelectedFilters(newFilters)}
              aria-label='billing filters'
            >
              <ToggleButton
                size='small'
                value='agreementProduct'
                aria-label='agreementProduct'
                selected={selectedFilters.includes('agreementProduct')}
                sx={{
                  backgroundColor: selectedFilters.includes('agreementProduct')
                    ? 'secondary.light'
                    : 'primary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light', // Bakgrunn for valgt knapp
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.dark', // Endrer farge ved hover når valgt
                  },
                }}
              >
                {t('Billing.billingView.agreementBottomHeader')}
              </ToggleButton>

              <ToggleButton
                size='small'
                value='investmentProduct'
                aria-label='investmentProduct'
                selected={selectedFilters.includes('investmentProduct')}
                sx={{
                  backgroundColor: selectedFilters.includes('investmentProduct')
                    ? 'secondary.light'
                    : 'primary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light',
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                {t('Billing.billingView.investmentSubHeader')}
              </ToggleButton>

              <ToggleButton
                size='small'
                value='supportTicket'
                aria-label='supportTicket'
                selected={selectedFilters.includes('supportTicket')}
                sx={{
                  backgroundColor: selectedFilters.includes('supportTicket')
                    ? 'secondary.light'
                    : 'primary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light',
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                {t('Billing.billingView.supportSubHeader')}
              </ToggleButton>

              <ToggleButton
                size='small'
                value='otherProduct'
                aria-label='otherProduct'
                selected={selectedFilters.includes('otherProduct')}
                sx={{
                  backgroundColor: selectedFilters.includes('otherProduct')
                    ? 'secondary.light'
                    : 'primary.main',
                  '&.Mui-selected': {
                    backgroundColor: 'secondary.light',
                  },
                  '&:hover': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                {t('Billing.billingView.otherSubHeader')}
              </ToggleButton>
            </ToggleButtonGroup>

            <Stack direction='row' spacing={2}>
              {/* Første knapp for å starte faktureringsprosessen */}
              <Button
                size='small'
                variant='contained'
                onClick={(e) => handleFirstInvoiceClick(e)}
              >
                {t('Billing.billingView.createInvoiceBttn')}
              </Button>

              {/* Bekreftelsesknappen som vises etter første knapptrykk */}

              <Button
                disabled={!confirmInvoice}
                sx={{ backgroundColor: 'secondary.main' }}
                size='small'
                variant='contained'
                onClick={(e) => handleConfirmInvoice(e)}
              >
                {t('Billing.billingView.confirmInvoiceBttn')}
              </Button>
            </Stack>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
