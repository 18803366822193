import { IRateViewModel } from 'data/atoms/atomConfig';

export const checkForOvertime = (
  rates: IRateViewModel[],
  hours: any,
  minutes: any
) => {
  let isOverTime = false;
  if (rates == null) {
    return false;
  }
  if (
    hours < rates[1].startHour ||
    (hours === rates[1].startHour && minutes < 0)
  ) {
    isOverTime = true;
  } else if (
    hours > rates[1].endHour ||
    (hours === rates[1].endHour && minutes > 14)
  ) {
    isOverTime = true;
  } else {
    isOverTime = false;
  }
  return isOverTime;
};
