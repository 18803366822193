import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { SearchFilter } from 'components/ui/SearchFilter';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useGet, usePost } from 'http/useInnovit';
import { useNavigate } from 'react-router';

import {
  EnumAddressType,
  EnumObjectType,
  EnumOrderPostStatus,
  EnumOrderStatus,
  EnumProductVersion,
  IAddressViewModel,
  IDeliveryAddressViewModel,
  MissingPhoto,
  objectArray,
} from 'Interfaces/IGlobalTypeConfig';
import { useAtom } from 'jotai';
import ownerAtom from 'data/atoms/ownerAtom';
import useWindowsDimension from 'functions/useWindowsDimension';
import { rq, rqPost } from 'http/apiRoutes';
import {
  IOrderCompanyViewModel,
  IOrderPostViewModel,
  IOrderState,
  IHeaderOrderListItem,
  IOrderContactViewModel,
  IOrderAddViewModel,
  IOrderInitialStateData,
  IOrderPostSimpleViewModel,
  IOrderPostTicketListViewModel,
  IOrderPostTicketViewModel,
  IOrderTicketViewModel,
} from 'Interfaces/IOrderConfig';
import { DeliveryAddressView } from 'components/ui/DeliveryAddressView';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { SingleOrderPostProductView } from './OrderPost/SingleOrderPostProductView';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { ObjectDrawer } from 'pages/Companies/ObjectDrawer';
import { SingleOrderCompanyView } from 'components/ui/SingleOrderCompanyView';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import dayjs from 'dayjs';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import GenerateGuid from 'functions/GenerateGuid';
import userAtom from 'data/atoms/userAtom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useGetObjectList } from 'functions/useGetObjectList';
import { atom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SingleOrderTicketView } from './OrderPost/SingleOrderTicketView';
import {
  iProductSimpleViewModel as IProductSimpleViewModel,
  IProductViewModel,
} from 'Interfaces/IProductConfig';
import { ISimpleCompanyInfoViewModel } from 'Interfaces/ICompaniesConfig';
import _ from 'lodash';
import { ISimpleEmployeeViewModel } from 'Interfaces/IEmployeeConfig';

const activeHeaderIndexAtom = atom<any>(0);

const _addressList: IDeliveryAddressViewModel[] = [
  {
    id: '0',
    addressId: null,
    addressType: EnumAddressType.owner,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '1',
    addressId: null,
    addressType: EnumAddressType.company,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '2',
    addressId: null,
    addressType: EnumAddressType.department,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '3',
    addressId: null,
    addressType: EnumAddressType.employee,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
  {
    id: '4',
    addressId: null,
    addressType: EnumAddressType.custom,
    street: '',
    postalCode: '',
    area: '',
    city: '',
    country: '',
    postBox: '',
    attention: '',
  },
];
const GenerateAddressList = (
  owner: any,
  companyDeliveryAddress: IAddressViewModel | null,
  employee: IOrderContactViewModel | null,
  addressList: IDeliveryAddressViewModel[]
): IDeliveryAddressViewModel[] => {
  console.log('tmp 1', addressList);
  let tmp: IDeliveryAddressViewModel[] = _.cloneDeep(_addressList);
  console.log('tmp 2', tmp, owner);

  if (owner != null) {
    tmp[0].addressId = owner.deliveryAddress.id;
    tmp[0].street = owner.deliveryAddress.street;
    tmp[0].postalCode = owner.deliveryAddress.postalCode.toString() ?? '';
    tmp[0].area = owner.deliveryAddress.area;
    tmp[0].city = owner.deliveryAddress.city;
    tmp[0].country = owner.deliveryAddress.country;
    tmp[0].postBox = owner.deliveryAddress.postBox ?? '';
    tmp[0].attention = addressList[0].attention ?? '';
  }

  console.log('tmp 3', tmp);

  if (companyDeliveryAddress != null) {
    if (
      companyDeliveryAddress.street !== addressList[1].street &&
      addressList[1].street !== ''
    ) {
    } else {
      tmp[1].addressId = companyDeliveryAddress.id ?? '';
      tmp[1].street = companyDeliveryAddress.street ?? '';
      tmp[1].postalCode = companyDeliveryAddress.postalCode.toString() ?? '';
      tmp[1].area = companyDeliveryAddress.area ?? '';
      tmp[1].city = companyDeliveryAddress.city ?? '';
      tmp[1].country = companyDeliveryAddress.country ?? '';
      tmp[1].postBox = companyDeliveryAddress.postBox ?? '';
      tmp[1].attention = addressList[1].attention ?? '';
    }
  }

  console.log('tmp 4', tmp);

  if (employee != null && employee.departmentAddress != null) {
    if (
      employee.departmentAddress.street !== addressList[2].street &&
      addressList[2].street !== ''
    ) {
    } else {
      tmp[2].addressId = employee.departmentAddress.id;
      tmp[2].street = employee.departmentAddress.street;
      tmp[2].postalCode = employee.departmentAddress.postalCode.toString();
      tmp[2].area = employee.departmentAddress.area;
      tmp[2].city = employee.departmentAddress.city;
      tmp[2].country = employee.departmentAddress.country;
      tmp[2].postBox = employee.departmentAddress.postBox ?? '';
      tmp[2].attention = addressList[2].attention ?? '';
    }
  }

  console.log('tmp 5', tmp);

  if (
    (employee != null &&
      employee.homeAddress != null &&
      addressList[3].addressId === null) ||
    (employee != null &&
      employee.homeAddress != null &&
      addressList[3].addressId === employee.homeAddress.id)
  ) {
    tmp[3].addressId = employee.homeAddress.id;
    tmp[3].street = employee.homeAddress.street;
    tmp[3].postalCode = employee.homeAddress.postalCode.toString();
    tmp[3].area = employee.homeAddress.area;
    tmp[3].city = employee.homeAddress.city;
    tmp[3].country = employee.homeAddress.country;
    tmp[3].postBox = employee.homeAddress.postBox ?? '';
    tmp[3].attention = addressList[3].attention ?? '';
  }

  console.log('tmp 6', tmp);

  tmp[4].addressId = addressList[4].id ?? '';
  tmp[4].street = addressList[4].street ?? '';
  tmp[4].postalCode = addressList[4].postalCode ?? '';
  tmp[4].area = addressList[4].area ?? '';
  tmp[4].city = addressList[4].city ?? '';
  tmp[4].country = addressList[4].country ?? '';
  tmp[4].postBox = addressList[4].postBox ?? '';
  tmp[4].attention = addressList[4].attention ?? '';

  console.log('tmp 7', tmp);

  return tmp;
};

// Conversion function
function convertToOrderPostSimpleViewModel(
  orderPost: IOrderPostViewModel,
  prevProduct: IProductSimpleViewModel
): IOrderPostSimpleViewModel {
  return {
    orderPostId: prevProduct.orderPostId,
    orderNo: '', // Assuming this needs to be set separately if needed
    status: EnumOrderStatus.Ordered, // Map your status accordingly
    companyId: orderPost.companyId,
    orderedQuantity: prevProduct.orderedQuantity,
    ownerId: prevProduct.productOwner?.id ?? null,
    ticketId: prevProduct.ticketId,
    ticketProductId: prevProduct.ticketProductId,
    productId: prevProduct.productId,
    unitPrice: prevProduct.unitPrice || 0,
    retailPrice: prevProduct.retailPrice || 0,
    quantity: prevProduct.originalQuantity || 0,
    deliveredQuantity: prevProduct.alreadyOrderedQuantity,
    serialNumber: '',
  };
}
function createOrderAddViewModel(
  order: any,
  vendor: IOrderCompanyViewModel,
  deliveryAddress: IDeliveryAddressViewModel,
  responsibleId: string,
  orderCost: number,
  shippingCost: number
): IOrderAddViewModel {
  const orderPostsSimple: IOrderPostSimpleViewModel[] =
    order.orderPostsList.flatMap((orderPost: IOrderPostViewModel) =>
      orderPost.prevProducts.map((prevProduct: IProductSimpleViewModel) =>
        convertToOrderPostSimpleViewModel(orderPost, prevProduct)
      )
    );
  console.log('onSubmit- orderPostsSimple', orderPostsSimple);
  return {
    vendorId: vendor.id,
    orderCost: orderCost,
    shippingCost: shippingCost,
    orderPosts: orderPostsSimple,
    attention: deliveryAddress.attention ?? '',
    deliveryArea: deliveryAddress.area,
    deliveryCity: deliveryAddress.city,
    deliveryCountry: deliveryAddress.country,
    deliveryPostalCode: deliveryAddress.postalCode.toString(),
    deliveryStreet: deliveryAddress.street,
    deliveryPostBox: deliveryAddress.postBox ?? '',
    responsibleId,
  };
}

type Action =
  | { type: 'RESET_FORM'; payload: number; activeHeaderIndex: number }
  | {
      type: 'SET_SHIPPING_COST';
      value: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_VENDOR';
      payload: IOrderCompanyViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'ADD_RIGHT_SIDE_HEADER_ITEM';
      payload: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'REMOVE_RIGHT_SIDE_HEADER_ITEM';
      payload: number;
      activeHeaderIndex: number;
    }
  | {
      type: 'SWITCH_ORDER_PRODUCT';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_ACTIVE_ORDER_POST_INDEX';
      payload: number;
    }
  | {
      type: 'ADD_ORDER_PRODUCT';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_LEFT_SIDE_ORDER_POST';
      payload: IOrderPostViewModel[];
      activeHeaderIndex: number;
    }
  | {
      type: 'REMOVE_LEFT_SIDE_ORDER_POST';
      payload: IOrderPostViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_DELIVERY_ADDRESSES';
      payload: IDeliveryAddressViewModel[];
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_DELIVERY_ADDRESS';
      payload: IDeliveryAddressViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'UPDATE_DELIVERY_ADDRESS';
      payload: IDeliveryAddressViewModel;
      activeHeaderIndex: number;
      addressIndex: number;
    }
  | {
      type: 'SET_NEW_PRODUCTS';
      payload: IBillingProductViewModel[];
    }
  | {
      type: 'SET_ORDER_POST_TICKET_LIST_VIEW';
      payload: IOrderPostTicketListViewModel;
      activeHeaderIndex: number;
    }
  | {
      type: 'SET_IS_ADDED_TO_ORDER_LIST';
      payload: {
        ticketId: string;
        productId: string;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'REMOVE_RIGHT_SIDE_ORDER_PRODUCT';
      payload: {
        ticketId: string;
        productId: string;
        productOwnerId: string;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'UPDATE_LEFT_SIDE_LIST';
      payload: {
        ticketId: string;
        productId: string;
        productOwnerId: string;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'CHANGE_LEFT_SIDE_SELECTED_PRODUCT_QUANTITY';
      payload: {
        ticketId: string;
        productId: string;
        productOwnerId: string;
        quantity: number;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'CHANGE_RIGHT_SIDE_SELECTED_PRODUCT_QUANTITY';
      payload: {
        ticketId: string;
        productId: string;
        productOwnerId: string;
        quantity: number;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'UPDATE_ORDER_POSTS_PRICE';
      payload: {
        orderPost: IOrderPostViewModel;
        price: number;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'RIGHT_CHANGE_PRODUCT';
      payload: {
        selectedProduct: any;
        orderPost: IOrderPostViewModel;
        activeHeaderIndex: number;
      };
    }
  | {
      type: 'CALCULATE_ORDER_COST';
      payload: {
        activeHeaderIndex: number;
      };
    };
const initialStateHeaderOrderList: IHeaderOrderListItem[] = [
  {
    id: 0,
    url: null,
    orderState: {
      vendor: null,
      deliveryAddressList: _addressList,
      orderPostsList: [],
      deliveryAddress: _addressList[0],
      totalOrderCost: 0,
      shippingCost: 0,
    } as IOrderState,
  },
];

const isSaveOrderButtonDisabled = (
  headerOrderListItem: IHeaderOrderListItem
) => {
  let isDisabled = true;
  if (
    !!headerOrderListItem.orderState.vendor?.id &&
    !!headerOrderListItem.orderState.deliveryAddress.street &&
    headerOrderListItem.orderState.orderPostsList.length > 0
  ) {
    isDisabled = false;
  }
  return isDisabled;
};

const initialState: any = {
  orderList: initialStateHeaderOrderList, // right side object
  orderPostTicketListView: {} as IOrderPostTicketListViewModel,
  activeOrderPostIndex: null,
};

const ConvertFromIOrderTicket = (
  ticketId: string,
  companyId: string,
  obj: IProductSimpleViewModel
) => {
  return {
    id: obj.id,
    orderPostId: obj.orderPostId,
    status: EnumOrderPostStatus.Ordered,
    deliveryDate: dayjs(new Date()).add(4, 'day'),
    companyId: companyId,
    company: null,
    companyContactId: null,
    companyContact: null,
    ticketId: ticketId,
    ticket: null,
    productId: obj.id,
    product: null,
    productVersion: EnumProductVersion.OrderProduct,
    ticketProductId: obj.id,
    ticketProduct: null,
    unitPrice: obj.unitPrice,
    retailPrice: obj.retailPrice,
    quantity: obj.originalQuantity - obj.alreadyOrderedQuantity,
    deliveredQuantity: 0,
    serialNumbers: [],
    isSerialNumber: false,
    originalQuantity: obj.originalQuantity,
    originalUnitPrice: obj.unitPrice,
    originalRetailPrice: obj.retailPrice,
    url: obj.url ?? '',
    responsibleId: '',
    prevOrderPostId: null,
    prevProducts: [obj],
  } as IOrderPostViewModel;
};

function stateReducer(state: any, action: Action): any {
  let _orderList = _.cloneDeep(state.orderList);
  let _orderPostsList = _.cloneDeep(state.orderPostsList);

  switch (action.type) {
    case 'SET_IS_ADDED_TO_ORDER_LIST': {
      const { ticketId, productId, activeHeaderIndex } = action.payload;

      // Klone hele state for å sikre immutabilitet
      const newState = JSON.parse(JSON.stringify(state));

      const ticketIndex = newState.orderPostTicketListView.orderPosts.findIndex(
        (orderPost: IOrderPostTicketViewModel) =>
          orderPost.ticketId === ticketId
      );

      if (ticketIndex !== -1) {
        const productIndex = newState.orderPostTicketListView.orderPosts[
          ticketIndex
        ].ticketProducts.findIndex(
          (ticketProduct: IProductSimpleViewModel) =>
            ticketProduct.productId === productId // Ignorerer ticketId her
        );

        if (productIndex !== -1) {
          // Oppdater klonede objekter
          const product =
            newState.orderPostTicketListView.orderPosts[ticketIndex]
              .ticketProducts[productIndex];
          product.isAddedToOrderList = true;
          product.orderedQuantity =
            product.originalQuantity - product.alreadyOrderedQuantity;

          // Sammenlign bare productId for å slå sammen produkter
          const existingOrderPostIndex = newState.orderList[
            activeHeaderIndex
          ]?.orderState?.orderPostsList?.findIndex(
            (op: IOrderPostViewModel) => op.productId === productId // Sammenlign kun productId her
          );

          if (existingOrderPostIndex !== -1) {
            const productCopy = JSON.parse(JSON.stringify(product));

            // Bruk samme pris som det eksisterende produktet i ordren
            productCopy.unitPrice =
              newState.orderList[activeHeaderIndex].orderState.orderPostsList[
                existingOrderPostIndex
              ].unitPrice;
            productCopy.retailPrice =
              newState.orderList[activeHeaderIndex].orderState.orderPostsList[
                existingOrderPostIndex
              ].retailPrice;

            newState.orderList[activeHeaderIndex].orderState.orderPostsList[
              existingOrderPostIndex
            ].prevProducts.push(productCopy);

            newState.orderList[activeHeaderIndex].orderState.orderPostsList[
              existingOrderPostIndex
            ].quantity +=
              productCopy.originalQuantity - product.alreadyOrderedQuantity;
          } else {
            let orderPostToAdd = ConvertFromIOrderTicket(
              ticketId,
              newState.orderPostTicketListView.orderPosts[ticketIndex]
                .companyInfo.id,
              product
            );

            // Sett prisen til den nye ordren lik den eksisterende hvis den finnes
            const anyOrderPostWithSameProductId = newState.orderList[
              activeHeaderIndex
            ].orderState.orderPostsList.find(
              (op: IOrderPostViewModel) => op.productId === productId // Sammenlign kun productId her
            );

            if (anyOrderPostWithSameProductId) {
              orderPostToAdd.unitPrice =
                anyOrderPostWithSameProductId.unitPrice;
              orderPostToAdd.retailPrice =
                anyOrderPostWithSameProductId.retailPrice;
            }

            newState.orderList[
              activeHeaderIndex
            ]?.orderState?.orderPostsList.push(orderPostToAdd);
          }

          // Oppdater prisen til produktet på venstre side hvis produktet allerede er lagt til ordrelisten
          newState.orderPostTicketListView.orderPosts.forEach(
            (ticket: IOrderPostTicketViewModel) => {
              ticket.ticketProducts.forEach(
                (ticketProduct: IProductSimpleViewModel) => {
                  if (
                    ticketProduct.productId === productId && // Sammenlign kun productId her
                    ticketProduct.isAddedToOrderList
                  ) {
                    ticketProduct.unitPrice =
                      newState.orderList[
                        activeHeaderIndex
                      ].orderState.orderPostsList.find(
                        (op: IOrderPostViewModel) => op.productId === productId // Sammenlign kun productId her
                      )?.unitPrice || ticketProduct.unitPrice;
                    ticketProduct.retailPrice =
                      newState.orderList[
                        activeHeaderIndex
                      ].orderState.orderPostsList.find(
                        (op: IOrderPostViewModel) => op.productId === productId // Sammenlign kun productId her
                      )?.retailPrice || ticketProduct.retailPrice;
                  }
                }
              );
            }
          );
        }
      }
      return newState;
    }

    case 'REMOVE_RIGHT_SIDE_ORDER_PRODUCT': {
      const { ticketId, productId, productOwnerId, activeHeaderIndex } =
        action.payload;
      const newState: typeof state = JSON.parse(JSON.stringify(state)); // Anta at `state` har en forhåndsdefinert type basert på din tilstand

      newState.orderList[activeHeaderIndex].orderState.orderPostsList =
        newState.orderList[activeHeaderIndex].orderState.orderPostsList
          .map((orderPost: IOrderPostViewModel) => {
            // Fjern det riktige produktet
            orderPost.prevProducts = orderPost.prevProducts.filter(
              (p: IProductSimpleViewModel) =>
                !(
                  p.id === productId &&
                  ((p.productOwner?.id === productOwnerId &&
                    p.ticketId === ticketId) ||
                    (!p.productOwner && p.ticketId === ticketId))
                )
            );

            // Oppdater antallet for orderPost basert på antall produkter igjen
            orderPost.quantity = orderPost.prevProducts.reduce(
              (total: number, product: IProductSimpleViewModel) => {
                return total + (product.orderedQuantity || 0);
              },
              0
            );

            return orderPost;
          })
          .filter(
            (orderPost: IOrderPostViewModel) =>
              orderPost.prevProducts.length > 0
          ); // Fjern hele orderPost hvis ingen prevProducts er igjen

      return newState;
    }

    case 'UPDATE_LEFT_SIDE_LIST': {
      const { ticketId, productId, productOwnerId, activeHeaderIndex } =
        action.payload;
      const newState: typeof state = JSON.parse(JSON.stringify(state));

      // Finn riktig ticket i venstre side liste
      const ticketIndex = newState.orderPostTicketListView.orderPosts.findIndex(
        (orderPost: IOrderPostTicketViewModel) =>
          orderPost.ticketId === ticketId
      );

      if (ticketIndex !== -1) {
        // Finn riktig produkt under denne ticket
        const productIndex = newState.orderPostTicketListView.orderPosts[
          ticketIndex
        ].ticketProducts.findIndex(
          (product: IProductSimpleViewModel) =>
            product.id === productId &&
            (product.productOwner?.id === productOwnerId ||
              (!product.productOwner && product.ticketId === ticketId))
        );

        if (productIndex !== -1) {
          // Oppdater produktet
          const product =
            newState.orderPostTicketListView.orderPosts[ticketIndex]
              .ticketProducts[productIndex];
          product.isAddedToOrderList = false;
          product.orderedQuantity = 0;
        }
      }

      return newState;
    }

    case 'CHANGE_LEFT_SIDE_SELECTED_PRODUCT_QUANTITY': {
      const {
        ticketId,
        productId,
        productOwnerId,
        quantity,
        activeHeaderIndex,
      } = action.payload;

      const newState: typeof state = JSON.parse(JSON.stringify(state));

      // Sjekk om orderList eksisterer
      if (!newState.orderList || !newState.orderList[activeHeaderIndex]) {
        console.error('orderList or activeHeaderIndex is not defined');
        return newState; // returner uendret state hvis orderList eller indeksen ikke finnes
      }

      // Finn riktig ticket i listen
      const ticketIndex = newState.orderPostTicketListView.orderPosts.findIndex(
        (orderPost: IOrderPostTicketViewModel) =>
          orderPost.ticketId === ticketId
      );

      if (ticketIndex !== -1) {
        // Finn riktig produkt basert på productId og enten productOwnerId eller ticketId
        const productIndex = newState.orderPostTicketListView.orderPosts[
          ticketIndex
        ].ticketProducts.findIndex(
          (product: IProductSimpleViewModel) =>
            product.id === productId &&
            (product.productOwner?.id === productOwnerId ||
              productOwnerId === '' ||
              product.ticketId === ticketId)
        );

        if (productIndex !== -1) {
          // Oppdater quantity for det valgte produktet
          newState.orderPostTicketListView.orderPosts[
            ticketIndex
          ].ticketProducts[productIndex].orderedQuantity = quantity;
        }
      }

      return newState;
    }

    case 'CHANGE_RIGHT_SIDE_SELECTED_PRODUCT_QUANTITY': {
      const {
        ticketId,
        productId,
        productOwnerId,
        quantity,
        activeHeaderIndex,
      } = action.payload;

      const newState = JSON.parse(JSON.stringify(state));

      // Sjekk om orderList og orderList[activeHeaderIndex] eksisterer
      if (!newState.orderList || !newState.orderList[activeHeaderIndex]) {
        console.error('orderList or activeHeaderIndex is undefined');
        return newState; // returner uendret state hvis orderList eller activeHeaderIndex ikke finnes
      }

      // Finn og oppdater produktets mengde i orderPostsList basert på ticketId, productId og productOwnerId
      const orderPostsList = newState.orderList[activeHeaderIndex].orderState
        .orderPostsList as IOrderPostViewModel[];

      for (let orderPost of orderPostsList) {
        const productIndex = orderPost.prevProducts.findIndex(
          (p: IProductSimpleViewModel) =>
            p.id === productId &&
            (p.productOwner?.id === productOwnerId ||
              productOwnerId === '' ||
              p.ticketId === ticketId)
        );

        if (productIndex !== -1) {
          // Oppdater orderedQuantity for det aktuelle produktet
          orderPost.prevProducts[productIndex].orderedQuantity = quantity;

          // Oppdater orderedQuantity for orderPost
          orderPost.quantity = orderPost.prevProducts.reduce(
            (total: number, product: IProductSimpleViewModel) => {
              return Number(total + Number(product.orderedQuantity || 0));
            },
            0
          );

          break; // Avslutt løkken tidlig siden vi har funnet produktet
        }
      }

      // Re-beregn total order kostnad
      newState.orderList[activeHeaderIndex].orderState.totalOrderCost =
        orderPostsList.reduce(
          (total: number, orderPost: IOrderPostViewModel) => {
            return total + orderPost.unitPrice * orderPost.quantity;
          },
          0
        );

      return newState;
    }

    case 'UPDATE_ORDER_POSTS_PRICE': {
      const { orderPost, price, activeHeaderIndex } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      // Oppdater prisen for orderPost basert på id i orderPostsList på høyre side
      const orderPostsList =
        newState.orderList[activeHeaderIndex].orderState.orderPostsList;
      for (let op of orderPostsList) {
        if (op.id === orderPost.id) {
          op.unitPrice = Number(price);
          break; // Anta at det kun finnes ett unikt produkt per ownerId, avslutt løkken tidlig
        }
      }

      // Oppdater prisen for alle produkter som er isAddedToOrderList i orderPostTicketListView
      newState.orderPostTicketListView.orderPosts.forEach(
        (ticket: IOrderPostTicketViewModel) => {
          ticket.ticketProducts.forEach((product: IProductSimpleViewModel) => {
            if (
              product.isAddedToOrderList &&
              product.id === orderPost.productId
            ) {
              // Oppdater prisen på venstre side
              product.unitPrice = Number(price);

              // Finn indexen for orderPost i orderPostsList
              const orderPostIndex = newState.orderList[
                activeHeaderIndex
              ].orderState.orderPostsList.findIndex(
                (op: IOrderPostViewModel) =>
                  op.ticketProduct?.product?.id === product.id
              );

              // Hvis orderPost finnes i orderPostsList, oppdater prisen der også
              if (orderPostIndex !== -1) {
                newState.orderList[activeHeaderIndex].orderState.orderPostsList[
                  orderPostIndex
                ].unitPrice = Number(price);
              }
            }
          });
        }
      );

      console.log('SingleOrderPostProductView: newState', newState);
      return newState;
    }
    case 'RIGHT_CHANGE_PRODUCT': {
      const { selectedProduct, orderPost, activeHeaderIndex } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      console.log('onSubmit: selectedProduct', selectedProduct);
      // Finn og oppdater produktet i orderPostsList basert på id (høyre side)
      const orderPostsList =
        newState.orderList[activeHeaderIndex].orderState.orderPostsList;

      // Sjekk om produktet allerede finnes i listen på høyre side
      const existingProductIndex = orderPostsList.findIndex(
        (op: IOrderPostViewModel) => op.productId === selectedProduct.id
      );

      if (existingProductIndex !== -1) {
        // Hvis produktet allerede finnes, slå sammen mengdene og oppdater detaljene
        const existingProduct = orderPostsList[existingProductIndex];

        // Oppdater prisen til det eksisterende produktet
        existingProduct.unitPrice = selectedProduct.unitPrice;
        existingProduct.retailPrice = selectedProduct.retailPrice;

        // Oppdater prisen for alle prevProducts til å matche den nye prisen
        orderPost.prevProducts.forEach((product: IProductSimpleViewModel) => {
          product.id = selectedProduct.id;
          product.name = selectedProduct.name;
          product.productId = selectedProduct.id;
          product.unitPrice = selectedProduct.unitPrice;
          product.retailPrice = selectedProduct.retailPrice;
          product.brand = selectedProduct.brand;
          product.brandId = selectedProduct.brandId;
          product.url = selectedProduct.url ?? '';
        });

        // Slå sammen mengdene
        existingProduct.quantity += orderPost.quantity;
        existingProduct.prevProducts.push(...orderPost.prevProducts);

        // Fjern det opprinnelige produktet
        const orderPostIndex = orderPostsList.findIndex(
          (op: IOrderPostViewModel) => op.id === orderPost.id
        );
        if (orderPostIndex !== -1) {
          orderPostsList.splice(orderPostIndex, 1);
        }
      } else {
        // Hvis produktet ikke finnes, oppdater det eksisterende orderPost
        for (let op of orderPostsList) {
          if (op.id === orderPost.id) {
            op.prevOrderPostId = '';
            op.orginalUnitPrice = op.unitPrice;
            op.orginalRetailPrice = op.retailPrice;
            op.id = selectedProduct.id;
            op.productId = selectedProduct.id;
            op.unitPrice = selectedProduct.unitPrice;
            op.retailPrice = selectedProduct.retailPrice;
            op.url = selectedProduct.url ?? '';
            op.prevProducts.forEach((product: IProductSimpleViewModel) => {
              product.id = selectedProduct.id;
              product.name = selectedProduct.name;
              product.productId = selectedProduct.id;
              product.unitPrice = selectedProduct.unitPrice;
              product.retailPrice = selectedProduct.retailPrice;
              product.brand = selectedProduct.brand;
              product.brandId = selectedProduct.brandId;
              product.url = selectedProduct.url ?? '';
            });
            break; // Anta at det kun finnes ett unikt produkt per ownerId, avslutt løkken tidlig
          }
        }
      }

      // Finn og oppdater produktet i orderPostTicketListView basert på id (venstre side)
      newState.orderPostTicketListView.orderPosts.forEach(
        (ticket: IOrderPostTicketViewModel) => {
          ticket.ticketProducts.forEach((product: IProductSimpleViewModel) => {
            if (
              product.id === orderPost.productId &&
              product.isAddedToOrderList
            ) {
              product.id = selectedProduct.id;
              product.name = selectedProduct.name;
              product.unitPrice = selectedProduct.unitPrice;
              product.retailPrice = selectedProduct.retailPrice;
              product.brand = selectedProduct.brand;
              product.brandId = selectedProduct.brandId;
              product.url = selectedProduct.url ?? '';
            }
          });
        }
      );

      // Oppdater prisene for eventuelle produkter på venstre side som allerede er lagt til ordrelisten
      newState.orderPostTicketListView.orderPosts.forEach(
        (ticket: IOrderPostTicketViewModel) => {
          ticket.ticketProducts.forEach((product: IProductSimpleViewModel) => {
            if (
              product.isAddedToOrderList &&
              product.productId === selectedProduct.id
            ) {
              product.unitPrice = selectedProduct.unitPrice;
              product.retailPrice = selectedProduct.retailPrice;
            }
          });
        }
      );
      console.log('handleChangeProduct: newState', newState);
      return newState;
    }
    case 'CALCULATE_ORDER_COST': {
      const { activeHeaderIndex } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      const orderPostsList =
        newState.orderList[activeHeaderIndex].orderState.orderPostsList;
      const shippingCost =
        newState.orderList[activeHeaderIndex].orderState.shippingCost;

      let totalOrderCost = 0;

      // Kalkuler total kostnad av alle produkter i orderPostsList
      for (const orderPost of orderPostsList) {
        totalOrderCost += orderPost.unitPrice * orderPost.quantity;
      }

      // Legg til fraktkostnad
      totalOrderCost += shippingCost;

      // Oppdater totalOrderCost i state
      newState.orderList[activeHeaderIndex].orderState.totalOrderCost =
        totalOrderCost;

      return newState;
    }

    case 'SET_ORDER_POST_TICKET_LIST_VIEW':
      return {
        ...state,
        orderPostTicketListView: action.payload,
      };
    case 'SET_SHIPPING_COST':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.shippingCost =
        +action.value;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'RESET_FORM':
      return initialState;
    case 'SET_LEFT_SIDE_ORDER_POST':
      return {
        ...state,
        orderPostsList: action.payload,
      };
    case 'ADD_RIGHT_SIDE_HEADER_ITEM':
      const newItem: IHeaderOrderListItem = {
        id: state.orderList.length, // or some other logic to assign a unique id
        url: MissingPhoto.COMPANY_URL,
        orderState: {
          vendor: null,
          deliveryAddressList: _addressList,
          orderPostsList: [],
          deliveryAddress: _addressList[4],
          totalOrderCost: 0,
          shippingCost: 0,
        } as IOrderState,
      };
      return {
        ...state,
        orderList: [...state.orderList, newItem],
      };
    case 'REMOVE_RIGHT_SIDE_HEADER_ITEM': {
      let _orderList = _.cloneDeep(state.orderList); // Cloning the orderList

      // Check if the orderList has only one item
      if (_orderList.length === 1) {
        // Replace the only item with a new default item
        return {
          ...state,
          orderList: [initialStateHeaderOrderList[0]],
          activeHeaderIndex: 0, // Reset activeHeaderIndex since the list is replaced
        };
      } else {
        // Filter out the item with the given id
        const updatedOrderList = _orderList.filter(
          (item: IHeaderOrderListItem) => item.id !== action.payload
        );

        // Calculate the new activeHeaderIndex
        let newActiveHeaderIndex = state.activeHeaderIndex;
        if (state.activeHeaderIndex >= updatedOrderList.length) {
          newActiveHeaderIndex = updatedOrderList.length - 1; // Adjust index if it's out of bounds
        }

        return {
          ...state,
          orderList: updatedOrderList,
          activeHeaderIndex: newActiveHeaderIndex, // Update the activeHeaderIndex
        };
      }
    }
    case 'SET_ACTIVE_ORDER_POST_INDEX': {
      return { ...state, activeOrderPostIndex: action.payload };
    }
    case 'SWITCH_ORDER_PRODUCT':
      const activeOrderPostsList =
        state.orderList[action.activeHeaderIndex].orderState.orderPostsList;

      const productToBeReplaced =
        activeOrderPostsList[state.activeOrderPostIndex];

      const updatedOrderPosts = activeOrderPostsList.map(
        (orderPost: IOrderPostViewModel, index: number) => {
          // Replace the order post at the active index
          if (index === state.activeOrderPostIndex) {
            return {
              ...action.payload,
              prevOrderPostId: productToBeReplaced.id,
            };
          }
          return orderPost; // Keep all other order posts as they are
        }
      );

      // Now, we update the state with these updated order posts
      return {
        ...state,
        orderList: state.orderList.map(
          (orderListItem: { orderState: any }, index: number) => {
            if (index === action.activeHeaderIndex) {
              return {
                ...orderListItem,
                orderState: {
                  ...orderListItem.orderState,
                  orderPostsList: updatedOrderPosts,
                },
              };
            }
            return orderListItem;
          }
        ),
      };

    case 'ADD_ORDER_PRODUCT': {
      // Check if the specified order exists and has an initialized orderPostList
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }

      if (
        !_orderList[action.activeHeaderIndex] ||
        !_orderList[action.activeHeaderIndex].orderState ||
        !Array.isArray(
          _orderList[action.activeHeaderIndex].orderState.orderPostsList
        )
      ) {
        // If not, initialize orderPostList as an empty array
        if (!_orderList[action.activeHeaderIndex]) {
          _orderList[action.activeHeaderIndex] = {
            orderState: { orderPostsList: [] },
          };
        } else if (!_orderList[action.activeHeaderIndex].orderState) {
          _orderList[action.activeHeaderIndex].orderState = {
            orderPostsList: [],
          };
        } else {
          _orderList[action.activeHeaderIndex].orderState.orderPostsList = [];
        }
      }
      // check if the orderPost already exists in the list
      const existingPostIndex = _orderList[
        action.activeHeaderIndex
      ].orderState.orderPostsList.findIndex(
        (p: IOrderPostViewModel) => p.id === action.payload.id
      );
      // add qunatity to existing post
      if (existingPostIndex !== -1) {
        _orderList[action.activeHeaderIndex].orderState.orderPostsList[
          existingPostIndex
        ].quantity += action.payload.quantity;
      } else {
        // Add the new product in an immutable way
        _orderList[action.activeHeaderIndex].orderState.orderPostsList = [
          ..._orderList[action.activeHeaderIndex].orderState.orderPostsList,
          action.payload,
        ];
      }
      // calculate total cost
      let totalCost = 0;
      _orderList[action.activeHeaderIndex].orderState.orderPostsList.forEach(
        (post: IOrderPostViewModel) => {
          totalCost += post.unitPrice * post.quantity;
        }
      );
      _orderList[action.activeHeaderIndex].orderState.totalOrderCost =
        totalCost;
      return {
        ...state,
        orderList: _orderList,
      };
    }
    case 'REMOVE_LEFT_SIDE_ORDER_POST':
      _orderPostsList = _orderPostsList.filter(
        (orderPost: IOrderPostViewModel) => orderPost.id !== action.payload.id
      );
      return {
        ...state,
        orderPostsList: _orderPostsList,
      };

    case 'SET_VENDOR':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      } else {
        console.log(
          'activeHeaderIndex is found at setVendor',
          action.activeHeaderIndex
        );
      }
      _orderList[action.activeHeaderIndex].orderState.vendor = action.payload;
      _orderList[action.activeHeaderIndex].url = action.payload.url ?? '';
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_DELIVERY_ADDRESSES':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddressList =
        action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_DELIVERY_ADDRESS':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddress =
        action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'UPDATE_DELIVERY_ADDRESS':
      if (!_orderList[action.activeHeaderIndex]) {
        console.log(
          'could not find activeHeaderIndex',
          action.activeHeaderIndex
        );
        return state;
      }
      _orderList[action.activeHeaderIndex].orderState.deliveryAddressList[
        action.addressIndex
      ] = action.payload;
      return {
        ...state,
        orderList: _orderList,
      };
    case 'SET_NEW_PRODUCTS':
      return {
        ...state,
        products: action.payload,
      };
  }
}

export const OrderPosts = () => {
  const { t } = useTranslation();

  // INITIALIZATION
  const { height } = useWindowsDimension();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [activeHeaderIndex, setActiveHeaderIndex] = useState<number>(0);
  const [confirmSave, setConfirmSave] = useState(false);

  const [owner] = useAtom(ownerAtom);
  const [user] = useAtom(userAtom);
  const [isOrdrePostsLoaded, setIsOrdrePostsLoaded] = useState<boolean>(false);
  // api
  const [isObjectList, setIsObjectList] = useState<boolean>(true);
  let companyId = 'new';
  let employeeId = 'new';
  let departmentId = 'new';
  let resourceId = 'new';
  let objectDrawerList: objectArray[] = useGetObjectList(
    companyId ?? 'new',
    employeeId ?? 'new',
    departmentId ?? 'new',
    resourceId ?? 'new',
    isObjectList
  );
  useEffect(() => {
    setIsObjectList(false);
  }, [objectDrawerList]);

  const validFieldTypes: Array<'unitPrice' | 'retailPrice' | 'quantity'> = [
    'unitPrice',
    'retailPrice',
    'quantity',
  ];
  let navigate = useNavigate();

  // API
  let isEnabled: boolean = isOrdrePostsLoaded ? false : true;
  const { data: orderPostData } = useGet(
    rq.orderPosts('0', isEnabled),
    isEnabled
  );

  const postOrder = usePost(rqPost.order('0', isEnabled), isEnabled);
  const postOrderPost = usePost(rqPost.orderPost('0', isEnabled), isEnabled);

  let orderPostsList = state.orderList[0].orderState.orderPostsList;

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleAddItem = () => {
    if (state.orderList.length < 7) {
      dispatch({
        type: 'ADD_RIGHT_SIDE_HEADER_ITEM',
        payload: 0,
        activeHeaderIndex: activeHeaderIndex,
      });
    }
  };
  const handleSelectionChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: number | null
  ): void => {
    if (newValue !== null) {
      setActiveHeaderIndex(newValue);
    }
  };
  type FormOrMouseEvent =
    | React.FormEvent<HTMLFormElement>
    | React.MouseEvent<HTMLDivElement, MouseEvent>;

  const handleChangeVendor = (
    event: FormOrMouseEvent,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    switch (objectType) {
      case EnumObjectType.Vendor:
        let _vendor = selectedObject as IOrderCompanyViewModel;
        dispatch({
          type: 'SET_VENDOR',
          payload: _vendor,
          activeHeaderIndex: activeHeaderIndex,
        });
        break;
      default:
        break;
    }
  };
  const handleAddProductToOrderList = (
    event: FormOrMouseEvent,
    selectedObject: IOrderPostTicketViewModel,
    product: IProductSimpleViewModel
  ) => {
    event.preventDefault();
    console.log('handleOrderTicketAssign', selectedObject);
    console.log('handleOrderTicketAssign product', product);

    dispatch({
      type: 'SET_IS_ADDED_TO_ORDER_LIST',
      payload: {
        ticketId: selectedObject.ticketId,
        productId: product.id,
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    dispatch({
      type: 'CALCULATE_ORDER_COST',
      payload: {
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    console.log(
      'handleAddNewToOrderProduct',
      state.orderList[activeHeaderIndex].orderState.deliveryAddressList
    );
    console.log('handleAddNewToOrderProduct', owner, selectedObject);
    let addresses = GenerateAddressList(
      owner,
      selectedObject.companyInfo.deliveryAddress,
      null,
      state.orderList[activeHeaderIndex].orderState.deliveryAddressList ?? []
    );
    console.log('handleAddNewToOrderProduct', addresses);
    dispatch({
      type: 'SET_DELIVERY_ADDRESSES',
      payload: addresses,
      activeHeaderIndex: activeHeaderIndex,
    });
  };
  const handleChangeDeliveryAddress = (
    deliveryAddress: IDeliveryAddressViewModel
  ) => {
    dispatch({
      type: 'SET_DELIVERY_ADDRESS',
      payload: deliveryAddress,
      activeHeaderIndex: activeHeaderIndex,
    });
    let addressIndex = Number(deliveryAddress.addressType);
    dispatch({
      type: 'UPDATE_DELIVERY_ADDRESS',
      payload: deliveryAddress,
      activeHeaderIndex: activeHeaderIndex,
      addressIndex: addressIndex,
    });
    dispatch({
      type: 'CALCULATE_ORDER_COST',
      payload: {
        activeHeaderIndex: activeHeaderIndex,
      },
    });
  };
  const handleRemoveProduct = (
    e: any,
    selectedObject: IProductSimpleViewModel
  ) => {
    e.preventDefault();

    // Sjekk om productOwner eller id er null eller undefined
    const productOwnerId = selectedObject.productOwner?.id || ''; // Hvis productOwner ikke finnes, sett id til tom streng

    dispatch({
      type: 'REMOVE_RIGHT_SIDE_ORDER_PRODUCT',
      payload: {
        ticketId: selectedObject.ticketId,
        productId: selectedObject.id,
        productOwnerId: productOwnerId, // Hvis productOwner er null, send en tom streng
        activeHeaderIndex: activeHeaderIndex,
      },
    });

    dispatch({
      type: 'UPDATE_LEFT_SIDE_LIST',
      payload: {
        ticketId: selectedObject.ticketId,
        productId: selectedObject.id,
        productOwnerId: productOwnerId, // Send også her en tom streng hvis productOwner er null
        activeHeaderIndex: activeHeaderIndex,
      },
    });
  };

  const handleChangeProductPrice = (
    e: FormOrMouseEvent,
    orderPost: IOrderPostViewModel,
    price: number
  ) => {
    e.preventDefault();
    console.log('handleChangeProductPrice : orderpost', orderPost);
    console.log('handleChangeProductPrice : price', price);
    dispatch({
      type: 'UPDATE_ORDER_POSTS_PRICE',
      payload: {
        orderPost: orderPost,
        price: price,
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    dispatch({
      type: 'CALCULATE_ORDER_COST',
      payload: {
        activeHeaderIndex: activeHeaderIndex,
      },
    });
  };
  const handleChangeProduct = (
    event: FormOrMouseEvent,
    selectedObject: any,
    orderPost: IOrderPostViewModel
  ) => {
    event.preventDefault();
    console.log('handleChangeProduct : selectedObject', selectedObject);
    console.log('handleChangeProduct : orderPost', orderPost);
    dispatch({
      type: 'RIGHT_CHANGE_PRODUCT',
      payload: {
        selectedProduct: selectedObject,
        orderPost: orderPost,
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    dispatch({
      type: 'CALCULATE_ORDER_COST',
      payload: {
        activeHeaderIndex: activeHeaderIndex,
      },
    });
  };
  const handleAddNewToOrderProduct = (
    event: any,
    selectedObject: any,
    objectType: EnumObjectType
  ) => {
    event.preventDefault();
    //console.log('handleChangeAddProduct', selectedObject);
    //converting the product to orderPost
    let _orderPost: IOrderPostViewModel = {
      id: GenerateGuid(),
      status: EnumOrderPostStatus.InTransit,
      deliveryDate: null,
      companyId: owner.id,
      company: null,
      companyContactId: null,
      companyContact: null,
      ticketId: null,
      ticket: null,
      productId: selectedObject.id,
      product: selectedObject,
      productVersion: EnumProductVersion.OrderProduct, //key information
      ticketProductId: null,
      ticketProduct: {
        id: null,
        companyProduct: null,
        product: selectedObject,
        ticket: null,
      },
      unitPrice: selectedObject.unitPrice,
      retailPrice: selectedObject.retailPrice,
      quantity: 1,
      deliveredQuantity: 0,
      serialNumbers: null,
      isSerialNumber: false,
      originalQuantity: 1,
      originalUnitPrice: selectedObject.unitPrice,
      originalRetailPrice: selectedObject.retailPrice,
      url: selectedObject.url ?? '',
      responsibleId: '',
      prevOrderPostId: null,
      prevProducts: [],
    };
    dispatch({
      type: 'ADD_ORDER_PRODUCT',
      payload: _orderPost,
      activeHeaderIndex: activeHeaderIndex,
    });
    let addresses = GenerateAddressList(
      owner,
      null,
      null,
      state.orderList[activeHeaderIndex].orderState.deliveryAddressList
    );
    console.log('handleAddNewToOrderProduct', addresses);
    dispatch({
      type: 'SET_DELIVERY_ADDRESSES',
      payload: addresses,
      activeHeaderIndex: activeHeaderIndex,
    });
  };
  const handleChangeOrderQuantity = (
    e: any,
    selectedObject: IProductSimpleViewModel
  ) => {
    e.preventDefault();
    console.log('handleChangeQuantity', selectedObject);
    console.log('handleChangeQuantity e ', e.target.value);
    let quantity = 0;
    if (
      Number(e.target.value) >
      selectedObject.originalQuantity - selectedObject.alreadyOrderedQuantity
    ) {
      quantity =
        selectedObject.originalQuantity - selectedObject.alreadyOrderedQuantity;
    } else if (Number(e.target.value) < 1) {
      quantity = 1;
    } else {
      quantity = Number(e.target.value);
    }
    dispatch({
      type: 'CHANGE_LEFT_SIDE_SELECTED_PRODUCT_QUANTITY',
      payload: {
        ticketId: selectedObject.ticketId,
        productId: selectedObject.id,
        productOwnerId: selectedObject?.productOwner?.id ?? null,
        quantity: quantity,
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    dispatch({
      type: 'CHANGE_RIGHT_SIDE_SELECTED_PRODUCT_QUANTITY',
      payload: {
        ticketId: selectedObject.ticketId,
        productId: selectedObject.id,
        productOwnerId: selectedObject?.productOwner?.id ?? null,
        quantity: quantity,
        activeHeaderIndex: activeHeaderIndex,
      },
    });
    dispatch({
      type: 'CALCULATE_ORDER_COST',
      payload: {
        activeHeaderIndex: activeHeaderIndex,
      },
    });
  };

  // USE EFFECTS

  useEffect(() => {
    console.log(
      'state.orderList',
      state.orderList[activeHeaderIndex].orderState.orderPostsList
    );
  }, [activeHeaderIndex, state.activeHeaderIndex, state.orderList]);

  // orders

  useEffect(() => {
    if (orderPostData) {
      console.log('ooo: orderPostData', orderPostData);

      // Opprett en mappe for å gruppere produkter basert på ticketNo
      let ticketMap = new Map<string, IOrderPostTicketViewModel>();

      orderPostData.orderPosts.forEach((orderPost: any) => {
        console.log('orderPost - convert', orderPost);
        const ticket = orderPost.ticketProduct.ticket;
        const company = orderPost.company as ISimpleCompanyInfoViewModel;
        let ticketEntry = ticketMap.get(ticket.ticketNo);

        // Hvis ticketNo ikke finnes i mappen, opprett en ny entry
        if (!ticketEntry) {
          ticketEntry = {
            ticketId: ticket.id,
            ticketNo: ticket.ticketNo,
            companyInfo: {
              id: company.id,
              name: company.name,
              organizationNumber: company.organizationNumber,
              phone: company.phone,
              email: company.email,
              url: company.url ?? '',
              priority: company.priority,
              onHold: company.onHold,
              onHoldNote: company.onHoldNote,
              deliveryAddress: {
                id: company.deliveryAddress.id,
                area: company.deliveryAddress.area,
                city: company.deliveryAddress.city,
                country: company.deliveryAddress.country,
                postBox: company.deliveryAddress.postBox,
                postalCode: company.deliveryAddress.postalCode,
                street: company.deliveryAddress.street,
              },
            },
            ticketProducts: [],
          };
          ticketMap.set(ticket.ticketNo, ticketEntry);
        }

        // Legg til produktet i listen over produkter for denne ticketNo
        let checkProduct = {} as IProductSimpleViewModel;
        let productOwner = {} as ISimpleEmployeeViewModel;
        if (orderPost.ticketProduct.companyProduct) {
          console.log('orderPost.ticketProduct.companyProduct', orderPost);
          checkProduct = orderPost.ticketProduct.companyProduct.product;
          productOwner = orderPost.ticketProduct.companyProduct.owner;
        } else if (orderPost.ticketProduct.product) {
          console.log('orderPost.ticketProduct.product', orderPost);
          checkProduct = orderPost.ticketProduct.product;
          productOwner = {} as ISimpleEmployeeViewModel;
        }
        ticketEntry.ticketProducts.push({
          orderPostId: orderPost.id,
          id: checkProduct.id,
          productId: checkProduct.id,
          name: checkProduct.name,
          url: checkProduct.url,
          retailPrice: checkProduct.retailPrice ?? 0,
          unitPrice: checkProduct.unitPrice ?? 0,
          brandId: checkProduct.brand.id,
          brand: {
            id: checkProduct.brand.id,
            brandName: checkProduct.brand.brandName,
            isActive: true,
          },
          alreadyOrderedQuantity: orderPost.orderedQuantity,
          orderedQuantity: 0,
          deliveredQuantity: orderPost.deliveredQuantity,
          originalQuantity: orderPost.quantity,
          productOwner: productOwner,
          isAddedToOrderList: false,
          ticketId: ticket.id,
          ticketProductId: orderPost.ticketProduct.id,
          ticketNo: ticket.ticketNo,
          ProductVariant: orderPost.ticketProduct.productVariant,
          productType: orderPost.ticketProduct.productType,
        });
      });

      // Lag IOrderPostTicketListViewModel
      let orderPostTicketListViewModel: IOrderPostTicketListViewModel = {
        orderPosts: Array.from(ticketMap.values()),
      };

      console.log(
        'onSubmit: orderPostTicketListViewModel',
        orderPostTicketListViewModel
      );
      dispatch({
        type: 'SET_ORDER_POST_TICKET_LIST_VIEW',
        payload: orderPostTicketListViewModel,
        activeHeaderIndex: activeHeaderIndex,
      });

      // convertTo productListview

      const _orderPostsMap = orderPostData.orderPosts.reduce(
        (
          acc: { [key: string]: IOrderPostViewModel },
          obj: IOrderPostViewModel
        ) => {
          const key = obj.ticketProduct?.companyProduct?.productId || obj.id; // Bruker ticketProductId hvis den finnes, ellers id
          if (!acc[key]) {
            acc[key] = {
              id: obj.id,
              status: obj.status,
              deliveryDate: obj.deliveryDate || null,
              companyId: obj.companyId || null,
              company: obj.company || null,
              companyContactId: obj.companyContactId || null,
              companyContact: obj.companyContact || null,
              ticketId: obj.ticketId || null,
              ticket: obj.ticket || null,
              productId: obj.productId || null,
              product: obj.product || null,
              productVersion: obj.productVersion,
              ticketProductId: obj.ticketProductId || null,
              ticketProduct: obj.ticketProduct || null,
              unitPrice: obj.unitPrice,
              retailPrice: obj.retailPrice,
              quantity: obj.quantity,
              originalQuantity: obj.quantity,
              originalUnitPrice: obj.unitPrice,
              originalRetailPrice: obj.retailPrice,
              deliveredQuantity: obj.deliveredQuantity,
              serialNumbers: obj.serialNumbers || null,
              isSerialNumber: obj.isSerialNumber,
              url: obj.url || '',
              responsibleId: obj.responsibleId,
              prevOrderPostId: obj.prevOrderPostId || null,
              prevProducts: obj.prevProducts || null,
            };
          } else {
            acc[key].quantity += obj.quantity;
            acc[key].originalQuantity += obj.quantity;
            acc[key].unitPrice = obj.unitPrice;
            acc[key].retailPrice = obj.retailPrice;
            acc[key].originalUnitPrice = obj.unitPrice;
            acc[key].originalRetailPrice = obj.retailPrice;
          }

          return acc;
        },
        {}
      );

      const _orderPosts: IOrderPostViewModel[] = Object.values(_orderPostsMap);

      dispatch({
        type: 'SET_LEFT_SIDE_ORDER_POST',
        payload: _orderPosts,
        activeHeaderIndex: activeHeaderIndex,
      });
      setIsOrdrePostsLoaded(true);
    }
  }, [orderPostData]);
  // vendors

  // ON SUBMIT
  const onSubmit = () => {
    let _order = _.cloneDeep(state.orderList[activeHeaderIndex].orderState);
    let vendor = _.cloneDeep(
      state.orderList[activeHeaderIndex].orderState.vendor
    );
    let deliveryAddress = _.cloneDeep(
      state.orderList[activeHeaderIndex].orderState.deliveryAddress
    );

    let newOrder = createOrderAddViewModel(
      _order,
      vendor,
      deliveryAddress,
      user.id,
      _order.totalOrderCost,
      _order.shippingCost
    );
    console.log(
      'onSubmit- state',
      state.orderList[activeHeaderIndex].orderState
    );
    let orderPost =
      state.orderList[activeHeaderIndex].orderState.orderPostsList[0];

    console.log('onSubmit- newOrder', newOrder);

    postOrder.mutate(newOrder, {
      onSuccess: (res: any) => {
        dispatch({
          type: 'REMOVE_RIGHT_SIDE_HEADER_ITEM',
          payload: 0,
          activeHeaderIndex: activeHeaderIndex,
        });
        let addresses = GenerateAddressList(
          owner,
          null,
          null,
          state.orderList[activeHeaderIndex].orderState.deliveryAddressList
        );
        dispatch({
          type: 'SET_DELIVERY_ADDRESSES',
          payload: addresses,
          activeHeaderIndex: activeHeaderIndex,
        });
      },
    });
  };

  const activeOrderPostsList =
    state.orderList[activeHeaderIndex].orderState.orderPostsList;
  const hasZeroUnitPrice = activeOrderPostsList.some(
    (post: { unitPrice: number }) => post.unitPrice === 0
  );

  console.log(
    'state.orderList[activeHeaderIndex].orderState.orderPostsList',
    state.orderList[activeHeaderIndex].orderState.orderPostsList,
    hasZeroUnitPrice
  );
  // console.log('objectListData', objectListData);
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* Left side */}
      <Grid item xs={6} sx={{ pr: 0.5, m: 0 }}>
        <SearchFilter
          Icon={CreditCardIcon}
          Header={t('Orders.OrderPosts.unmanagedProducts')}
        />
        <Grid
          item
          xs={12}
          sx={{
            p: 0,
            m: 0,
            backgroundColor: 'primary.main',
          }}
        >
          <List
            sx={{
              p: 2,
              height: Math.ceil(height - 252),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!state.orderPostTicketListView.orderPosts &&
              state.orderPostTicketListView.orderPosts.map(
                (orderPost: IOrderPostTicketViewModel, index: number) => (
                  <Grid
                    sx={{
                      backgroundColor: 'transparent',
                      border: 0,
                      boxShadow: 'none',
                    }}
                    key={index}
                  >
                    <SingleOrderTicketView
                      handleOrderTicketAssign={handleAddProductToOrderList}
                      orderPost={orderPost}
                    />
                  </Grid>
                )
              )}
          </List>
        </Grid>
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            height: 64,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        ></Paper>
      </Grid>

      {/*   RIGHT SIDE    */}
      <Grid item xs={6} sx={{ pl: 0.5, m: 0 }}>
        {/*   HEADER    */}
        <Paper
          variant='elevation3'
          sx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <Grid container display={'flex'} flexDirection={'row'}>
            <Grid item xs={1} sx={{ p: 0, m: 0 }}>
              <CreditCardIcon sx={{ fontSize: 32, m: 1.5 }} />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, m: 0 }}>
              <Typography
                sx={{
                  m: 2,
                  ml: -1,
                  pt: 0.5,
                  flexGrow: 1,
                  textTransform: 'uppercase',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontWeight: 'bold',
                  fontSize: 14,
                  letterSpacing: 2,
                }}
              >
                {t('Orders.OrderPosts.orders')}
              </Typography>
            </Grid>
            <Grid item xs={9} sx={{ p: 0, m: 0, mt: 0.7, pr: 1 }}>
              <ToggleButtonGroup
                sx={{
                  m: 0,
                  p: 0,
                  borderWidth: 0,
                  backgroundColor: 'primary.back',
                }}
                color='primary'
                size='small'
                orientation='horizontal'
                value={activeHeaderIndex}
                aria-label='text alignment'
              >
                {state.orderList.map(
                  (obj: IHeaderOrderListItem, index: number) => {
                    if (!obj || !obj.orderState) {
                      return null;
                    }
                    return (
                      <ToggleButton
                        key={index}
                        sx={{
                          m: 0.3,
                          p: 0,
                          borderRadius: 2,
                          borderColor:
                            index === activeHeaderIndex
                              ? 'primary.text'
                              : 'primary.main',
                        }}
                        value={index}
                        onClick={(e: any) =>
                          handleSelectionChange(e, index as number)
                        }
                        aria-label='left aligned'
                      >
                        <Grid
                          item
                          sx={{
                            m: 0,
                            p: 0,
                            width: 96,
                            borderRadius: 2,
                            height: 48,
                            backgroundColor: obj?.url
                              ? 'primary.light'
                              : 'primary.main',
                          }}
                        >
                          <img
                            src={obj?.url ?? MissingPhoto.COMPANY_URL}
                            style={{
                              margin: 0,
                              padding: 0,
                              borderTopLeftRadius: 20,
                              borderTopRightRadius: 20,
                              borderBottomLeftRadius: 20,
                              borderBottomRightRadius: 20,
                              width: '100%',
                              height: '100%',
                              borderRadius: 10,
                              objectFit: 'scale-down',
                            }}
                            alt={'logo'}
                          />
                        </Grid>
                      </ToggleButton>
                    );
                  }
                )}
                <Button
                  sx={{
                    color: 'primary.text',
                    backgroundColor: 'primary.light',
                    borderRadius: 2,
                    width: 48,
                    height: 54,
                    m: 0,
                    p: 0,
                    // outline: '1px solid red',
                  }}
                  variant='contained'
                  key={99}
                  value='addItem' // The value can be anything that distinguishes this button
                  onClick={handleAddItem}
                  disabled={true}
                >
                  <AddRoundedIcon sx={{ fontSize: 32 }} />
                </Button>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Paper>
        {/*    VENDOR INFO    */}
        <Paper
          variant='elevation3'
          sx={{
            p: 2,
            borderRadius: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            // backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
            }}
          >
            <Grid item xs={6} sx={{}}>
              {!!state.orderList[activeHeaderIndex].orderState?.vendor ? (
                <Grid
                  item
                  xs={12}
                  sx={{ backgroundColor: 'primary.back', borderRadius: 2 }}
                >
                  <Stack direction='row'>
                    <Grid item sx={{ flexGrow: 1 }}>
                      <SingleOrderCompanyView
                        company={
                          state.orderList[activeHeaderIndex]?.orderState?.vendor
                        }
                      />
                    </Grid>
                    <Grid item sx={{ p: 1 }}>
                      <ObjectDrawer
                        buttonContext={t('Orders.OrderPosts.vendor')}
                        buttonIcon={<ChangeCircleIcon sx={{ fontSize: 26 }} />}
                        objectList={!!objectDrawerList ? objectDrawerList : []}
                        objectType={[EnumObjectType.Vendor]}
                        handleChange={handleChangeVendor}
                        buttonHeight={32}
                        buttonWidth={32}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              ) : (
                <ObjectDrawer
                  buttonContext={t('Orders.OrderPosts.vendor')}
                  buttonIcon={null}
                  objectList={!!objectDrawerList ? objectDrawerList : []}
                  objectType={[EnumObjectType.Vendor]}
                  handleChange={handleChangeVendor}
                  buttonHeight={88}
                  buttonWidth={'100%'}
                />
              )}
            </Grid>
            <Grid item xs={1} sx={{}}></Grid>
            <Grid item xs={5} sx={{ m: 0, p: 0 }}>
              <Stack direction={'column'} sx={{}}>
                <Stack direction={'row'} sx={{}}>
                  <Typography
                    sx={{
                      m: 1,
                      pt: 0.5,
                      fontWeight: 'normal',
                      fontSize: 14,
                      width: '60%',
                    }}
                  >
                    {t('Orders.OrderPosts.totalOrderCost')}:
                  </Typography>
                  <Typography
                    sx={{
                      m: 1,
                      pt: 0.5,
                      width: '40%',
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: 'secondary.text',
                      textAlign: 'right',
                    }}
                  >
                    {state.orderList[
                      activeHeaderIndex
                    ].orderState.totalOrderCost.toFixed(2)}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 12,
                  }}
                >
                  {t('Orders.OrderPosts.shippingCost')}:
                </Typography>
                <TextField
                  sx={{
                    mt: -0.5,
                    '& .Mui-focused': {
                      backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    },
                    '& .MuiInputAdornment-root': {
                      '& p': {
                        fontSize: '14px',
                        textAlign: 'left',
                        marginLeft: -1,
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'secondary.text',
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: 'right',
                      fontWeight: 'bold',
                      fontSize: 14,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{
                          textAlign: 'left',
                          color: 'secondary.text',
                          fontWeight: 'bold',
                        }}
                      >
                        <p>.00</p>
                      </InputAdornment>
                    ),
                  }}
                  value={state.orderList[
                    activeHeaderIndex
                  ].orderState.shippingCost.toFixed()}
                  onFocus={handleFocus}
                  onChange={(e) =>
                    dispatch({
                      type: 'SET_SHIPPING_COST',
                      value: +e.target.value,
                      activeHeaderIndex: activeHeaderIndex,
                    })
                  }
                  /* onBlur={(e: any) => {
                    !!handleBlur &&
                      handleBlur(e, state, 'unitPrice', +e.target.value);
                  }}
                  disabled={isDisabled} */
                />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <SearchFilter
          Icon={ContactMailRoundedIcon}
          Header={t('Orders.OrderPosts.productToBeOrdered')}
          filterValue={null}
          handleFilterChange={null}
          handleSearch={null}
          handleNew={null}
          selectList={null}
          gfx={
            <ObjectDrawer
              buttonContext={t('Orders.OrderPosts.addProduct')}
              buttonIcon={<AddRoundedIcon sx={{ fontSize: 26 }} />}
              objectList={!!objectDrawerList ? objectDrawerList : []}
              objectType={[EnumObjectType.Product]}
              handleChange={handleAddNewToOrderProduct}
              buttonHeight={30}
              buttonWidth={30}
            />
          }
        />
        {/*   ORDER POSTS    */}
        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            m: 0,
            backgroundColor: 'primary.main',
          }}
        >
          <List
            sx={{
              p: 1,
              height: Math.ceil(height - 654),
              backgroundColor: 'primary.back',
              overflow: 'auto',
            }}
          >
            {!!state.orderList[activeHeaderIndex].orderState.orderPostsList &&
              state.orderList[activeHeaderIndex].orderState.orderPostsList?.map(
                (orderPost: IOrderPostViewModel, index: number) => (
                  <SingleOrderPostProductView
                    key={index}
                    orderPost={orderPost}
                    handleChangeProduct={handleChangeProduct}
                    handleRemoveProduct={handleRemoveProduct}
                    handleChangeOrderQuantity={handleChangeOrderQuantity}
                    handleChangeProductPrice={handleChangeProductPrice}
                    objectDrawerList={objectDrawerList}
                  />
                )
              )}
          </List>
        </Grid>

        <Paper
          variant='elevation3'
          sx={{
            p: 1,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <Grid item xs={12} sx={{ p: 0, m: 0, mb: 1 }}>
            <DeliveryAddressView
              addressList={
                state.orderList[activeHeaderIndex].orderState
                  .deliveryAddressList || []
              }
              handleChange={handleChangeDeliveryAddress}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              p: 0,
              m: 0,
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              variant='contained'
              //type='submit'
              onClick={
                hasZeroUnitPrice ? () => setConfirmSave(!confirmSave) : onSubmit
              }
              disabled={isSaveOrderButtonDisabled(
                state.orderList[activeHeaderIndex]
              )}
            >
              {t('Orders.OrderPosts.saveOrder')}
            </Button>
            {confirmSave && hasZeroUnitPrice ? (
              <>
                <Button
                  variant='contained'
                  sx={{ mr: 2 }}
                  color='secondary'
                  onClick={onSubmit}
                >
                  {t('Orders.OrderPosts.confirmSave')}
                </Button>
                <Typography sx={{ color: 'secondary', mr: 2, mt: 1 }}>
                  {t('Orders.OrderPosts.saveOrderNote')}
                </Typography>
              </>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
