import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import {
  EnumOrderPostStatus,
  MissingPhoto,
} from 'Interfaces/IGlobalTypeConfig';
import { IOrderPostViewModel } from 'Interfaces/IOrderConfig';
import { IBillingProductViewModel } from 'Interfaces/BillingConfig';
import _ from 'lodash';
import GetChartColor from 'functions/GetChartColor';
import { useTranslation } from 'react-i18next';
import {
  IProductAddViewModel,
  IProductViewModel,
} from 'Interfaces/IProductConfig';

type Props = {
  orderPost: IOrderPostViewModel;
  isDisabled: boolean;
  handleBlur: {
    (obj: IOrderPostViewModel): void;
  };
};

type Action =
  | { type: 'RESET_FORM' }
  | { type: 'UPDATE_RETAIL_PRICE'; value: number }
  | { type: 'ADD_SERIAL_NUMBER'; payload: string }
  | { type: 'REMOVE_SERIAL_NUMBER'; payload: string }
  | { type: 'SET_FORM'; payload: IOrderPostViewModel }
  | { type: 'SET_URL'; payload: string }
  | { type: 'UPDATE_UNIT_QUANTITY'; value: number };

const initialState: IOrderPostViewModel = {
  id: '',
  status: EnumOrderPostStatus.InTransit,
  deliveryDate: null,
  companyId: '',
  company: null,
  companyContactId: '',
  companyContact: null,
  ticketId: '',
  ticket: null,
  productId: '',
  product: null,
  productVersion: 0,
  ticketProductId: null,
  ticketProduct: null,
  unitPrice: 0,
  retailPrice: 0,
  quantity: 0,
  deliveredQuantity: 0,
  serialNumbers: null,
  isSerialNumber: false,
  originalQuantity: 0,
  originalUnitPrice: 0,
  originalRetailPrice: 0,
  url: null,
  responsibleId: '',
  prevOrderPostId: null,
  prevProducts: [],
};

function stateReducer(
  state: IOrderPostViewModel,
  action: Action
): IOrderPostViewModel {
  let _clonedState = _.cloneDeep(state);
  switch (action.type) {
    case 'RESET_FORM':
      return initialState;
    case 'UPDATE_RETAIL_PRICE':
      return {
        ...state,
        retailPrice: action.value,
      };
    case 'ADD_SERIAL_NUMBER':
      _clonedState.serialNumbers == null && (_clonedState.serialNumbers = []);

      if (_clonedState.serialNumbers) {
        if (_clonedState.deliveredQuantity < _clonedState.quantity) {
          _clonedState.deliveredQuantity++;
          _clonedState.serialNumbers.push(action.payload);
          _clonedState.isSerialNumber = false;
        }
      }
      if (_clonedState.serialNumbers) {
        if (_clonedState.deliveredQuantity === _clonedState.quantity) {
          _clonedState.isSerialNumber = true;
        }
      }
      //  console.log('cloned state serialNumbers:', _clonedState.serialNumbers);
      return {
        ...state,
        serialNumbers: _clonedState.serialNumbers,
        isSerialNumber: _clonedState.isSerialNumber,
        deliveredQuantity: _clonedState.deliveredQuantity,
      };
    case 'REMOVE_SERIAL_NUMBER':
      _clonedState.serialNumbers == null && (_clonedState.serialNumbers = []);
      if (_clonedState.serialNumbers) {
        if (_clonedState.serialNumbers.length > 0) {
          _clonedState.deliveredQuantity--;
          _clonedState.serialNumbers.pop();
          _clonedState.isSerialNumber = false;
        }
      }
      if (_clonedState.serialNumbers) {
        if (_clonedState.deliveredQuantity === _clonedState.quantity) {
          _clonedState.isSerialNumber = true;
        }
      }
      // console.log('cloned state serialNumbers:', _clonedState.serialNumbers);
      return {
        ...state,
        serialNumbers: _clonedState.serialNumbers,
        isSerialNumber: _clonedState.isSerialNumber,
        deliveredQuantity: _clonedState.deliveredQuantity,
      };

    case 'SET_FORM':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_URL':
      return {
        ...state,
        url: action.payload,
      };
    case 'UPDATE_UNIT_QUANTITY':
      return {
        ...state,
        quantity: action.value,
      };

    default:
      return state;
  }
}

export const SingleReceivedOrderPostView = ({
  orderPost,
  isDisabled,
  handleBlur,
}: Props) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(stateReducer, orderPost);
  const [isSerialNumberChanged, setIsSerialNumberChanged] =
    useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [previousSerialNumber, setPreviousSerialNumber] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [product, setProduct] = useState<IProductViewModel | IBillingProductViewModel | null>(
    {} as IProductViewModel
  );
  console.log('SingleReceivedOrderPostView', orderPost);
  // INITIALIZATION

  // API

  // HANDLES
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumber(event.target.value);
  };

  const handleSubmitSerialNumber = () => {
    dispatch({ type: 'ADD_SERIAL_NUMBER', payload: serialNumber });
    setSerialNumber('');
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      dispatch({ type: 'ADD_SERIAL_NUMBER', payload: serialNumber });
      setSerialNumber('');
      setPreviousSerialNumber(serialNumber);
      setIsSerialNumberChanged(true);
      // handleBlur(updatedState); // Pass the updated state
    }
  };

  const handleUndo = (event: any) => {
    event.preventDefault();
    dispatch({ type: 'REMOVE_SERIAL_NUMBER', payload: previousSerialNumber });
    //setPreviousSerialNumber('');
  };

  // BLUR
  const handleUndoBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    // console.log('handleBlur', event.target.name, event.target.value);
    setPreviousSerialNumber('');
  };
  // EFFECTS

  useEffect(() => {
    inputRef.current?.focus();
  }, [serialNumber]);

  useEffect(() => {
    if (isSerialNumberChanged) {
      handleBlur(state);
      setIsSerialNumberChanged(false);
    }
  }, [state.serialNumbers]);

  useEffect(() => {
    dispatch({ type: 'SET_FORM', payload: orderPost });
    let product = orderPost.ticketProduct?.product
      ? orderPost.ticketProduct.product
      : orderPost.ticketProduct?.companyProduct?.product
      ? orderPost.ticketProduct.companyProduct.product
      : orderPost?.product
      ? orderPost.product
      : null;
    setProduct(product);

    let url = orderPost.ticketProduct?.product?.url
      ? orderPost.ticketProduct?.product.url
      : orderPost.ticketProduct?.companyProduct?.product?.url
      ? orderPost.ticketProduct?.companyProduct.product.url
      : orderPost?.product?.url
      ? orderPost.product.url
      : null;
    //console.log('url', url);
    if (url) {
      dispatch({ type: 'SET_URL', payload: url });
    }
  }, [orderPost]);

  let color = 2;
  if (state.deliveredQuantity > 0) {
    color = state.deliveredQuantity === state.quantity ? 0 : 1;
  }
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        height: 114,
        mb: 2,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        zIndex: 1,
        backgroundColor: 'primary.main',
        borderColor: `${GetChartColor(color)}.lighter`,
        boxShadow:
          '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Grid
        item
        xs={1.5}
        sx={{
          p: 0.3,
          width: 112,
          height: 112,
          borderRadius: 2,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          backgroundColor: 'primary.light',
        }}
      >
        {state?.url != null ? (
          <img
            src={state.url}
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        ) : (
          <img
            src={MissingPhoto.PRODUCT_URL}
            style={{
              width: '100%',
              height: '100%',
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
              objectFit: 'scale-down',
            }}
            alt={'logo'}
          />
        )}
      </Grid>

      <Grid
        item
        xs={10.5}
        sx={{
          pl: 1,
        }}
      >
        <Stack direction={'column'}>
          <Typography
            sx={{
              letterSpacing: 1,
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            {product?.name}
          </Typography>

          <Grid
            container
            direction={'row'}
            sx={{
              height: 40,
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {product?.features &&
              product?.features.map((_features: any, index: number) => {
                return (
                  <Grid item key={index} xs={3}>
                    <Grid container direction={'row'}>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            fontSize: 10,
                            letterSpacing: 1,
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.featureName ||
                            `${t(
                              'Orders.SingleReceivedOrderPostView.feature'
                            )}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          noWrap
                          sx={{
                            fontSize: 10,
                            letterSpacing: 1,
                            fontWeight: 'normal',
                            textAlign: 'left',
                            color: 'primary.text',
                          }}
                        >
                          {_features.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Stack>
        <Grid item xs={12} sx={{}}>
          <Stack direction={'row'}>
            <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
              {color !== 0 && (
                <Stack direction={'column'} sx={{}}>
                  <Typography
                    sx={{
                      width: '100%',
                      letterSpacing: 1,
                      pl: 1,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      fontSize: 12,
                    }}
                  >
                    {t('Orders.SingleReceivedOrderPostView.serialNumber')}
                  </Typography>
                  <TextField
                    sx={{
                      mt: -0.5,
                      '& .Mui-focused': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      },
                      '& .MuiInputAdornment-root': {
                        '& p': {
                          fontSize: '14px',
                          textAlign: 'left',
                        },
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'right',
                        fontSize: 14,
                        paddingRight: 0,
                      },
                    }}
                    value={serialNumber}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    onFocus={handleFocus}
                    //onBlur={handleUndoBlur}
                    inputRef={inputRef}
                    disabled={state.isSerialNumber}
                  />
                </Stack>
              )}
            </Grid>
            <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
              {previousSerialNumber.length > 0 && (
                <Stack direction={'row'} sx={{ pt: 2 }}>
                  <Button
                    sx={{
                      color: 'primary.text',
                      backgroundColor: 'primary.light',
                      borderRadius: 2,
                      width: 48,
                      height: 24,
                      m: 0,
                      p: 0,
                    }}
                    variant='contained'
                    key={99}
                    value='undo' // The value can be anything that distinguishes this button
                    onClick={handleUndo}
                  >
                    {t('Orders.SingleReceivedOrderPostView.undo')}
                  </Button>
                  <Typography
                    sx={{
                      width: '100%',
                      letterSpacing: 1,
                      pl: 1,
                      pt: 0.2,
                      textAlign: 'left',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                      color: 'primary.text',
                      fontSize: 16,
                    }}
                  >
                    {previousSerialNumber}
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={4} sx={{ m: 0, p: 0, pr: 1 }}>
              <Stack direction={'row'} sx={{}}>
                <Typography
                  sx={{
                    width: '100%',
                    letterSpacing: 1,
                    pl: 1,
                    pt: 2,
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 18,
                  }}
                >
                  {t('Orders.SingleReceivedOrderPostView.quantity')}
                </Typography>
                <Typography
                  sx={{
                    width: '30%',
                    letterSpacing: 1,
                    pr: 1,
                    pt: 2,
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 18,
                  }}
                >
                  {state.deliveredQuantity}
                </Typography>
                <Typography
                  sx={{
                    width: '10%',
                    letterSpacing: 1,
                    pr: 1,
                    pt: 2,
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 18,
                  }}
                >
                  {'/'}
                </Typography>
                <Typography
                  sx={{
                    width: '30%',
                    letterSpacing: 1,
                    pr: 1,
                    pt: 2,
                    textAlign: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    fontSize: 18,
                  }}
                >
                  {state.quantity}
                </Typography>
              </Stack>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};
