import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IBillingCompanyProductViewModel } from 'Interfaces/BillingConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

interface SingleBillingAgreementViewProps {
  agreement: any;
}

const SingleBillingAgreementView: React.FC<SingleBillingAgreementViewProps> = ({
  agreement,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid
      container
      alignItems='center'
      sx={{ mb: 1, padding: 1, border: '1px solid lightgray', borderRadius: 3 }}
    >
      {/* Agreement Icon */}
      <Grid
        item
        xs={1}
        sx={{ width: 48, height: 48, backgroundColor: 'primary.light' }}
      >
        <img
          src={MissingPhoto.BILLING_AGREEMENT_URL} // Placeholder for Agreement icon
          style={{
            marginLeft: 5,
            width: 48,
            height: 48,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'Agreement'}
        />
      </Grid>

      {/* Agreement Name and Cost */}
      <Grid item xs={8}>
        <Typography variant='body1'>
          {agreement.product?.name || 'Unnamed Agreement'}
        </Typography>
        <Typography variant="body2">
          {agreement.quantity} x {agreement.unitPrice.toFixed(2)}{' '}
          &nbsp; | &nbsp; Total: {(agreement.quantity * agreement.unitPrice).toFixed(2)}
        </Typography>
      </Grid>

      {/* Expand Button */}
      <Grid item xs={1}>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>

      {/* Expanded Agreement Details */}
      {expanded && (
        <Grid item xs={12} sx={{ pl: 5, pt: 1 }}>
          <Typography variant='body2'>Details:</Typography>
          <Typography variant='body2'>
            Quantity: {agreement.quantity}
          </Typography>
          <Typography variant='body2'>
            Unit Price: {agreement.retailPrice.toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
          </Typography>
          <Typography variant='body2'>
            Total:{' '}
            {(agreement.retailPrice * agreement.quantity).toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
          </Typography>
          {agreement.monthsNotBilled > 1 && (
            <Typography color='error'>
              {`Manglende fakturering for ${agreement.monthsNotBilled} måneder`}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBillingAgreementView;
