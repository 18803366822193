import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IBillingCompanyProductViewModel } from 'Interfaces/BillingConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

interface SingleBillingInvestmentViewProps {
  investment: IBillingCompanyProductViewModel;
}

const SingleBillingInvestmentView: React.FC<SingleBillingInvestmentViewProps> = ({ investment }) => {
  const [expanded, setExpanded] = useState(false);
  console.log('investment',investment);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid container alignItems="center" sx={{ mb: 1, padding: 1, border: '1px solid lightgray', borderRadius: 3 }}>
      {/* Investment Icon */}
      <Grid item xs={1} sx={{ width: 48, height: 48, backgroundColor: 'primary.light' }}>
        <img
          src={MissingPhoto.BILLING_PRODUCT_URL} // Placeholder for Investment icon
          style={{
            marginLeft: 5,
            width: 48,
            height: 48,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'Investment'}
        />
      </Grid>

      {/* Investment Name and Cost */}
      <Grid item xs={8}>
        <Typography variant="body1">{investment.product?.name || 'Unnamed Investment'}</Typography>
        <Typography variant="body2">
          Cost: {investment.retailPrice.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>

      {/* Expand Button */}
      <Grid item xs={1}>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>

      {/* Expanded Investment Details */}
      {expanded && (
        <Grid item xs={12} sx={{ pl: 5, pt: 1 }}>
          <Typography variant="body2">Details:</Typography>
          <Typography variant="body2">Quantity: {investment.quantity}</Typography>
          <Typography variant="body2">Unit Price: {investment.retailPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
          <Typography variant="body2">Total: {(investment.retailPrice * investment.quantity).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBillingInvestmentView;
