import React, { useState } from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ITicketViewModel, ITicketPostViewModel, ITicketBillingViewModel } from 'Interfaces/ITicketConfig';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';

interface SingleBillingTicketViewProps {
  ticket: ITicketBillingViewModel;
}

const SingleBillingTicketView: React.FC<SingleBillingTicketViewProps> = ({ ticket }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // Beregn totalen basert på `fixedPrice`, `maxPrice`, eller summen av `ticketPosts`
  const totalBillingValue = ticket.fixedPrice > 0
    ? ticket.fixedPrice
    : ticket.maxPrice > 0
    ? Math.min(ticket.maxPrice, ticket.ticketPosts.reduce(
        (sum: number, post: ITicketPostViewModel) => sum + post.totalBillingValue,
        0
      ))
    : ticket.ticketPosts.reduce(
        (sum: number, post: ITicketPostViewModel) => sum + post.totalBillingValue,
        0
      );

  return (
    <Grid container alignItems="center" sx={{ mb: 1, padding: 1, border: '1px solid lightgray', borderRadius: 3 }}>
      {/* Ticket Icon */}
      <Grid item xs={1} sx={{ width: 48, height: 48, backgroundColor: 'primary.light' }}>
        <img
          src={MissingPhoto.BILLING_SUPPORT_URL}
          style={{
            marginLeft: 5,
            width: 48,
            height: 48,
            borderRadius: 3,
            objectFit: 'scale-down',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
          }}
          alt={'logo'}
        />
      </Grid>
      
      {/* Ticket Number and Calculated Total */}
      <Grid item xs={8}>
        <Typography variant="body1">Ticket No: {ticket.ticketNo}</Typography>
        <Typography variant="body2">
          Total: {totalBillingValue.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Grid>

      {/* Expand Button */}
      <Grid item xs={1}>
        <IconButton onClick={handleExpandClick} aria-expanded={expanded}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Grid>

      {/* Expanded Ticket Posts */}
      {expanded && (
        <Grid item xs={12} sx={{ pl: 5, pt: 1 }}>
          {ticket.ticketPosts.map((post: ITicketPostViewModel, index: number) => (
            <Grid container key={index} alignItems="center" sx={{ mb: 1 }}>
              {/* Responsible Image */}
              <Grid item xs={1}>
                <img
                  src={post.author?.url || MissingPhoto.EMPLOYEE_URL}
                  alt="Responsible"
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Grid>
              {/* Ticket Post Message and Total Value */}
              <Grid item xs={8}>
                <Typography variant="body2">{post.message}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="right">
                  {post.totalBillingValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default SingleBillingTicketView;
