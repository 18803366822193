import React, { useState } from 'react';
import { Typography, Paper, Grid, Stack } from '@mui/material';
import { MissingPhoto } from 'Interfaces/IGlobalTypeConfig';
import GetColorByName from 'functions/GetColorByName';
import { IBillingPostViewModel } from 'Interfaces/BillingConfig';
import { BillingCompanyViewModel } from 'pages/Billing/BillingView';
import { ITicketBillingViewModel } from 'Interfaces/ITicketConfig';

// STYLE
const sxHeader = {
  pl: 1,
  pt: 0,
  width: '50&',
  letterSpacing: 2,
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 16,
};
const sxSubHeader = {
  pl: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'normal',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'primary.text',
};
const sxSubValue = {
  pl: 1,
  pr: 1,
  width: '100&',
  letterSpacing: 2,
  textAlign: 'right',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: 14,
  color: 'secondary.text',
};

type Props = {
  billingPost: BillingCompanyViewModel;
};

// Helper function to calculate the total supportInvoiceValue based on ticketBillingList and their ticketPosts
export const calculateSupportInvoiceValue = (
  ticketBillingList: ITicketBillingViewModel[]
): number => {
  let supportInvoiceValue = 0;

  ticketBillingList.forEach((ticket) => {
    if (ticket.fixedPrice > 0) {
      // If fixedPrice is present, use it directly for this ticket
      supportInvoiceValue += ticket.fixedPrice;
    } else if (ticket.maxPrice > 0) {
      // If maxPrice is present and no fixedPrice, use maxPrice
      supportInvoiceValue += ticket.maxPrice;
    } else {
      // If neither fixedPrice nor maxPrice is set, sum up the TotalBillingValue of each ticketPost
      ticket.ticketPosts.forEach((ticketPost) => {
        supportInvoiceValue += ticketPost.totalBillingValue;
      });
    }
  });

  return supportInvoiceValue;
};

const SingleBillingPostView = ({ billingPost }: Props) => {
  console.log('SingleBillingPostView: company: ', billingPost);

  let supportInvoiceValue = calculateSupportInvoiceValue(
    billingPost.ticketBillingList
  );
  return (
    <Paper
      elevation={3}
      //
      sx={{
        mb: 1,
        borderLeft: 8,
        borderRight: 3,
        borderRadius: 3,
        borderColor: GetColorByName(`${billingPost.company.name}`),
        height: 80,
        backgroundColor: 'primary.main',
        ':hover': {
          cursor: 'pointer',
          filter: 'brightness(120%)',
          transition: 'all 0.2s ease-in-out',
        },
      }}
    >
      <Stack display={'flex'} flexDirection={'row'}>
        <Grid
          item
          sx={{
            width: 160,
            height: 80,
            borderRadius: 3,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: 'primary.light',
          }}
        >
          <img
            src={billingPost.company?.url || MissingPhoto.COMPANY_URL}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 3,
              borderBottomRightRadius: 0,
              borderTopRightRadius: 0,
              objectFit: 'scale-down',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.50)',
            }}
            alt={'logo'}
          />
        </Grid>
        <Grid
          container
          display={'flex'}
          flexDirection='row'
          sx={{ m: 0, pl: 1 }}
        >
          <Grid item xs={12}>
            <Typography sx={sxHeader}>{billingPost.company.name}</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubHeader}>Agreement</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubValue}>
              {billingPost.agreementInvoiceValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubHeader}>Support</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubValue}>
              {supportInvoiceValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubHeader}>Investment</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubValue}>
              {billingPost.investmentInvoiceValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubHeader}>Other</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={sxSubValue}>
              {billingPost.otherInvoiceValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default SingleBillingPostView;
